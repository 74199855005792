import React, { useEffect } from "react";
import Navbar from "./components/NavBar/NavBar";
import "./App.css";
import Login from "./pages/Login/Login";
import Users from "./pages/Users/Users";
import About from "./pages/About/About";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Projetos from "./pages/Projetos/Projetos";
import Valores from "./pages/Valores/Valores";
import Modulos from "./pages/Modulos/Modulos";
import Itens from "./pages/ItensProjeto/Itens";
import Gerentes from "./pages/Gerentes/Gerentes";
import Clientes from "./pages/Clientes/Clientes";
import Header from "./components/header/Header";
import { useAuthContext } from "./hooks/useAuthContext";
import { baseURL } from "./components/utils/utils";
import Pagamentos from "./pages/Pagamentos/Pagamentos";
import Apontamentos from "./pages/Apontamentos/Apontamentos";
import Registrar from "./pages/Apontamentos/Registrar";
import Registros from "./pages/Apontamentos/Registros";
import Pagar from "./pages/Pagamentos/Pagar";
import Pagos from "./pages/Pagamentos/Pagos";
import NovaSenha from "./pages/Login/NovaSenha";
import Loading from "./components/Loading/Loading";

function App() {
  const { setUser, loading, setUrl } = useAuthContext();
  useEffect(() => {
    setUrl(baseURL);
    const storedUser = localStorage.getItem("user");
    if (storedUser !== null) {
      setUser(JSON.parse(storedUser));
    }
  }, [setUser, setUrl]);

  if (loading) {
    <Loading />;
    return <p>Carregando...</p>; // Exibe uma mensagem de carregamento
  }
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Navbar />
        <div className="container">
          <Routes>
            <Route path="/*" element={<Itens />} />
            <Route path="/itens" element={<Itens />} />
            <Route path="/login" element={<Login />} />
            <Route path="/senha" element={<NovaSenha />} />
            <Route path="/projetos" element={<Projetos />} />
            <Route path="/modulos" element={<Modulos />} />
            <Route path="/itens" element={<Itens />} />
            <Route path="/gerentes" element={<Gerentes />} />
            <Route path="/clientes" element={<Clientes />} />
            <Route path="/about" element={<About />} />
            <Route path="/users" element={<Users />} />
            <Route path="/valores" element={<Valores />} />
            <Route path="/apontamentos" element={<Apontamentos />} />
            <Route path="/registrar/:id" element={<Registrar />} />
            <Route path="/registrar" element={<Registrar />} />
            <Route path="/registros" element={<Registros />} />
            <Route path="/pagamentos" element={<Pagamentos />} />
            <Route path="/pagar" element={<Pagar />} />
            <Route path="/pagos" element={<Pagos />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;

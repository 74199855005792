import React, { useEffect, useRef, useState } from "react";
import Input from "../../components/Input/Input";
import styles from "../Pages.module.css";
import Button from "../../components/Button/Button";
import { baseURL } from "../../components/utils/utils";
import { useFetch } from "../../hooks/useFetch";
import { useAuthContext } from "../../hooks/useAuthContext";
import Table from "../../components/Table/Table";
import SelectObjetos from "../../components/Select/SelectObjetos";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
const Valores = () => {
  const navigate = useNavigate();
  const { httpGet, httpRequest, error, setError, loading } = useFetch(
    `${baseURL}/valores/`
  );

  const { httpGet: httpUsers } = useFetch(`${baseURL}/usuarios/`);

  const val1Ref = useRef();
  const iniRef = useRef();
  const usuRef = useRef();

  const [formError, setFormError] = useState("");
  const [isActive, setActive] = useState(false);

  //trata erros de formulário
  const [userError, setUserError] = useState("");
  const [datIniError, setDatIniError] = useState("");
  const [val1Error, setVal1Error] = useState("");

  const { user } = useAuthContext();
  const [data, setData] = useState([]);
  const [dadosTabela, setTabela] = useState([]);

  const [ValId, setId] = useState("");
  const [fk_UsuId, setUsuId] = useState("");
  const [ValDatIni, setDatIni] = useState(0);
  const [ValVal1, setVal1] = useState(0);
  const [valor, setValor] = useState({});
  const [valAnterior] = useState({});
  const [valAtual, setValAtual] = useState({});

  const head = ["Id", "Usuário", "Inicio", "Valor"];

  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    const getList = async () => {
      if (user && user.token) {
        if (!valor.fk_UsuId) {
          setUsuId(user.UsuId);
        }
        const usuarios = await httpUsers(user.token, "listar");
        setUsersList(usuarios); // Armazena os usuarios
        const dados = await httpGet(user.token, "listar");
        dados && setTabela(objToList(dados));
        dados && setValAtual(dados[0]);
        dados && setData(dados);
      } else {
        navigate("/login");
      }
    };
    getList();
  }, [valor]);

  useEffect(() => {
    if (user && !user.token) navigate("/login");
  }, []);

  // salva os dados do formulário
  const handleSave = async () => {
    setUsuId(user.UsuId);
    const nValor = await validaCampos();
    if (nValor) {
      var endpoint = "inserir";
      if (ValId > 0) {
        endpoint = `atualizar/${ValId}`;
      }
      console.log(nValor);
      const data = await httpRequest(nValor, user.token, endpoint);
      if (data) {
        setValor(data);
        handleCancel();
      }
    }
  };

  // valida os campos do formulário
  const validaCampos = async () => {
    //Valida o campo Usuario
    if (fk_UsuId === 0) {
      setFormError("O campo Usuário é obrigatório.");
      setUserError(true);
      usuRef.current.focus(); // Foca no campo Status
      return false;
    }

    if (!ValDatIni) {
      setFormError("O campo Data de início é obrigatório.");
      setDatIniError(true);
      iniRef.current.focus(); // Foca no campo data
      return false;
    }

    if (ValDatIni < valAtual.ValDatIni && valAtual.fk_UsuId === fk_UsuId) {
      setFormError("Já existe um histórico mais atual");
      setDatIniError(true);
      iniRef.current.focus(); // Foca no campo data
      return false;
    }

    if (!ValVal1) {
      setFormError("O campo Valor é obrigatório.");
      setVal1Error(true);
      val1Ref.current.focus(); // Foca no campo data
      return false;
    }
    if (ValVal1 > 0) {
      let dataValDatIni = new Date(ValDatIni);
      dataValDatIni.setDate(dataValDatIni.getDate());
      valAnterior.ValDatIni = dataValDatIni.toISOString().split("T")[0];

      const data = await httpRequest(
        valAnterior,
        user.token,
        `ultimoValor/${fk_UsuId}`
      );

      if (data && data[0].ValVal1 >= ValVal1) {
        setFormError(
          `O Valor atual não pode ser menor ou igual ao valor anterior R$${data[0].ValVal1}`
        );
        setVal1Error(true);
        val1Ref.current.focus(); // Foca no campo data
        return false;
      }
    }
    const nVal = {
      ValId,
      fk_UsuId,
      ValDatIni,
      ValVal1
    };

    return nVal;
  };

  //Alimenta as variaveis
  const handleClickUpdate = (valor) => {
    const eValor = data.find((val) => val.ValId === valor.ValId);
    setId(eValor.ValId);
    setUsuId(eValor.fk_UsuId);
    setDatIni(eValor.ValDatIni);
    setVal1(eValor.ValVal1);
    setValor(eValor);
    setActive(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // exclui a informação
  const handleClickdel = async (valor) => {
    const success = await httpRequest(
      valor,
      user.token,
      `remover/${valor.ValId}`
    );
    if (success) {
      setValor(data);
      handleCancel();
    }
    handleCancel();
  };

  //cancela a operação e limpa os campos
  const handleCancel = () => {
    setActive(false);
    setId(0);
    setDatIni(0);
    setVal1(0);
    handleCleanError();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCleanError = () => {
    setFormError("");
    setError("");
    setDatIniError(false);
    setVal1Error(false);
    setActive(false);
  };

  const handleSelectUsers = (e) => {
    console.log(e.target.value);
    setUsuId(e.target.value);
    setFormError("");
    setError("");
    setUsuId(e.target.value);
  };
  const objToList = (dados) => {
    return (
      dados &&
      dados.map((obj) =>
        Object.keys(obj).reduce((acc, key) => {
          if (
            key === "ValId" ||
            key === "fk_UsuId" ||
            key === "ValDatIni" ||
            key === "ValVal1"
          ) {
            acc[key] = obj[key];
          }
          return acc;
        }, {})
      )
    );
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Valor Hora</h1>
      <div className={styles.card}>
        {loading && <Loading />}
        <SelectObjetos
          ref={usuRef} // Referência para o campo Status
          label="Usuário"
          id="fk_UsuId"
          options={usersList}
          value={
            fk_UsuId && fk_UsuId
              ? fk_UsuId
              : user && user.UsuId
              ? user.UsuId
              : 0
          }
          onChange={(e) => handleSelectUsers(e)}
          hasError={userError}
          valueKey="UsuId"
          labelKey="UsuNome"
          disabled={user && user.fk_NivId > 2 ? true : false}
        />
        <Input
          ref={iniRef} // Referência para o campo Nome
          label="Início"
          id="ValDatIni"
          type="date"
          value={(ValDatIni && ValDatIni) || ""}
          onChange={(e) => {
            setDatIni(e.target.value);
            setDatIniError(false);
            handleCleanError();
            setActive(true);
          }}
          disabled={user && user.fk_NivId > 2 ? true : false}
          hasError={datIniError} // Aplica a classe de erro se houver erro no nome
        />
        <Input
          ref={val1Ref} // Referência para o campo Nome
          label="Valor"
          id="ValVal1"
          type="text"
          value={(ValVal1 && ValVal1) || ""}
          onChange={(e) => {
            setVal1(e.target.value);
            handleCleanError();
            setActive(true);
            setVal1Error(false);
          }}
          disabled={user && user.fk_NivId > 2 ? true : false}
          hasError={val1Error} // Aplica a classe de erro se houver erro no nome
        />

        {loading && <p className={styles.loading}>Carregando...</p>}
        {error && <p className={styles.error}>{error}</p>}
        {formError && <p className={styles.error}>{formError}</p>}
        <Button
          texto="Salvar"
          onClick={handleSave}
          active={user && user.fk_NivId > 2 ? false : true}
        />
        <Button
          texto="Cancelar"
          onClick={handleCancel}
          active={user && user.fk_NivId > 2 ? false : isActive}
        />
        <Table
          head={head}
          onClickUpdate={handleClickUpdate}
          onClickDel={handleClickdel}
          isMaster={user && user.fk_NivId > 2 ? false : true}
          listas={{ fk_UsuId: usersList }}
          data={dadosTabela && dadosTabela}
        />
      </div>
    </div>
  );
};

export default Valores;

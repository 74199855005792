import React, { useEffect, useRef, useState } from "react";
import Input from "../../components/Input/Input";
import styles from "../Pages.module.css";
import Select from "../../components/Select/Select";
import Button from "../../components/Button/Button";
import { baseURL } from "../../components/utils/utils";
import { useFetch } from "../../hooks/useFetch";
import { useAuthContext } from "../../hooks/useAuthContext";
import Table from "../../components/Table/Table";
import SelectObjetos from "../../components/Select/SelectObjetos";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";

const Itens = () => {
  const navigate = useNavigate();
  // instancia o useFetch de comunicação com API
  const { httpGet: httpProjetos } = useFetch(`${baseURL}/projetos/`);

  const { httpGet: httpModulos } = useFetch(`${baseURL}/modulos/`);

  const { httpGet: httpUsers } = useFetch(`${baseURL}/usuarios/`);

  const { httpGet, httpRequest, error, setError, loading } = useFetch(
    `${baseURL}/itens/`
  );

  const horRef = useRef();
  const usuRef = useRef();

  const projRef = useRef();
  const gerRef = useRef();
  const praRef = useRef();
  const statusRef = useRef();
  const [formError, setFormError] = useState("");
  const [isActive, setActive] = useState(false);

  //trata erros de formulário
  const [horasError, setHorasError] = useState("");
  const [prazoError, setPrazoError] = useState("");
  const [projError, setProjError] = useState("");
  const [moduloError, setModuloError] = useState("");
  const [statusError, setStatusError] = useState("");
  //const [userError, setUserError] = useState("");

  const { user, error: userError } = useAuthContext();
  const [data, setData] = useState([]);
  const [dadosTabela, setTabela] = useState([]);

  const head = ["Id", "Usuario", "Horas", "Status", "Prazo", "Descrição"];
  const statusOptions = [
    "...",
    "Pendente",
    "Em andamento",
    "Concluído",
    "Atrasado"
  ];

  //chaves estrangeiras

  const [moduloList, setModuloList] = useState([]);
  const [projetoList, setProjList] = useState([]);
  const [usersList, setUsersList] = useState([]);

  //atributos
  const [item, setItem] = useState([]);
  const [ItemId, setId] = useState(0);
  const [fk_ProjId, setProjId] = useState(0);
  const [fk_ModId, setModId] = useState(0);
  const [fk_UsuId, setUsuId] = useState(0);
  const [ItemHoras, setHoras] = useState(0);
  const [ItemPrazo, setPrazo] = useState(0);
  const [ItemStatus, setStatus] = useState(0);
  const [ItemDes, setDes] = useState("");

  useEffect(() => {
    const getList = async () => {
      if (user && user.token) {
        if (!item.fk_UsuId) {
          setUsuId(user.UsuId);
        }
        const projetos = await httpProjetos(user.token, "listar");
        setProjList(projetos); // Armazena os projetos

        const modulos = await httpModulos(user.token, "listar");
        setModuloList(modulos); // Armazena os modulos

        const usuarios = await httpUsers(user.token, "listar");
        setUsersList(usuarios); // Armazena os usuarios

        const dados = await httpGet(user.token, "listar");
        const dadosTabela = objToList(dados);
        dadosTabela && setTabela(dadosTabela);
        setData(dados);
        console.log(dados);
      } else {
        navigate("/login");
      }
    };
    getList();
  }, [item]);

  // salva os dados do formulário
  const handleSave = async (e) => {
    e.preventDefault();

    const nItem = validaCampos();
    console.log(nItem);
    if (nItem) {
      setUsuId(user.UsuId);
      var endpoint = "inserir";
      if (ItemId > 0) {
        endpoint = `atualizar/${ItemId}`;
      }

      const data = await httpRequest(nItem, user.token, endpoint);
      if (data) {
        setItem(data);
        handleCancel();
      }
    }
  };

  const objToList = (dados) => {
    return (
      dados &&
      dados.map((obj) =>
        Object.keys(obj).reduce((acc, key) => {
          if (
            key === "ItemId" ||
            key === "ItemDes" ||
            key === "fk_UsuId" ||
            key === "ItemHoras" ||
            key === "ItemPrazo" ||
            key === "ItemStatus"
          ) {
            acc[key] = obj[key];
          }
          return acc;
        }, {})
      )
    );
  };
  // Carrega os dados do item selecionado no formulário
  const handleClickUpdate = (itemEditing) => {
    const eItem = data.find((item) => item.ItemId === itemEditing.ItemId);
    let descricao = setDescricao();
    setId(eItem.ItemId);
    setPrazo(eItem.ItemPrazo);
    setStatus(eItem.ItemStatus); // Atualiza o status selecionado
    setProjId(eItem.fk_ProjId);
    setModId(eItem.fk_ModId);
    setUsuId(eItem.fk_UsuId);
    setHoras(eItem.ItemHoras);
    setDes(descricao);
    setItem(eItem);
    setActive(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // exclui a informação
  const handleClickdel = async (itemDeleting) => {
    setActive(true);
    const success = await httpRequest(
      itemDeleting,
      user.token,
      `remover/${itemDeleting.ItemId}`
    );
    if (success) {
      setItem(data);
      handleCancel();
    }
  };

  // valida os campos do formulário
  const validaCampos = () => {
    // Valida o campo Projeto
    if (fk_ProjId === 0) {
      setFormError("Selecione um Projeto.");
      setProjError(true);
      projRef.current.focus(); // Foca no campo Status
      return false;
    }
    // Valida o campo Modulo
    if (fk_ModId === 0) {
      setFormError("Selecione um Módulo.");
      setModuloError(true);
      gerRef.current.focus(); // Foca no campo Status
      return false;
    }

    if (!ItemHoras || isNaN(parseFloat(ItemHoras))) {
      setFormError("O campo Horas é obrigatório.");
      setHorasError(true);
      horRef.current.focus(); // Foca no campo Nome
      return false;
    }

    if (!ItemPrazo) {
      setFormError("O campo Data é obrigatório.");
      setPrazoError(true);
      praRef.current.focus(); // Foca no campo data
      return false;
    }

    // Valida o campo Status
    if (ItemStatus === 0) {
      setFormError("O campo Status é obrigatório.");
      setStatusError(true);
      statusRef.current.focus(); // Foca no campo Status
      return false;
    }

    const nItem = {
      ItemId,
      ItemStatus,
      ItemHoras,
      ItemPrazo,
      fk_ProjId,
      fk_ModId,
      fk_UsuId,
      ItemDes: setDescricao()
    };
    return nItem;
  };

  const setDescricao = () => {
    let descricao = "";

    if (fk_ProjId > 0) {
      const projeto = projetoList.find(
        (proj) => proj.ProjId === Number(fk_ProjId)
      );
      console.log(projetoList);
      descricao = projeto.ProjNome;
    }
    if (fk_ModId > 0) {
      const modulo = moduloList.find((mod) => mod.ModId === Number(fk_ModId));
      descricao = `${descricao} - ${modulo.ModNome}`;
    }
    setDes(descricao);
    return descricao;
  };

  //cancela a operação e limpa os campos
  const handleCancel = () => {
    setActive(false); // Desativa o botão "Cancelar"
    setUsuId(user.UsuId);
    setId(0);
    setPrazo(0);
    setHoras(0);
    setStatus(0);
    setProjId(0);
    setModId(0);
    handleCleanError();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleCleanError = () => {
    setFormError("");
    setError("");
  };
  //Selects:
  const handleSelectProjeto = (e) => {
    //console.log(e.target.value);
    setFormError("");
    setError("");
    setProjId(e.target.value);
  };
  const handleSelectModulo = (e) => {
    //console.log(e.target.value);

    setFormError("");
    setError("");
    setModId(e.target.value);
  };
  const handleSelectUsers = (e) => {
    //console.log(e.target.value);
    setUsuId(e.target.value);
    setFormError("");
    setError("");
    setUsuId(e.target.value);
  };

  const handleSelectStatus = (e) => {
    // console.log(e.target.value);
    setFormError("");
    setError("");
    setStatus(e.target.value);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Demandas</h1>

      <div className={styles.card}>
        <SelectObjetos
          ref={usuRef} // Referência para o campo Status
          label="Usuário"
          id="fk_UsuId"
          options={usersList}
          value={
            fk_UsuId && fk_UsuId
              ? fk_UsuId
              : user && user.UsuId
              ? user.UsuId
              : 0
          }
          onChange={(e) => handleSelectUsers(e)}
          hasError={userError}
          valueKey="UsuId"
          labelKey="UsuNome"
          statusField="UsuStatus"
          disabled={false}
        />
        <SelectObjetos
          ref={projRef} // Referência para o campo Status
          label="Projeto"
          id="fk_ProjId"
          options={projetoList}
          value={fk_ProjId}
          onChange={(e) => handleSelectProjeto(e)}
          hasError={projError}
          valueKey="ProjId"
          labelKey="ProjNome"
        />
        <SelectObjetos
          ref={gerRef} // Referência para o campo Status
          label="Módulo"
          id="fk_ModId"
          options={moduloList}
          value={fk_ModId}
          onChange={(e) => handleSelectModulo(e)}
          hasError={moduloError}
          valueKey="ModId"
          labelKey="ModNome"
        />

        <Input
          ref={horRef} // Referência para o campo Nome
          label="Horas"
          id="ItenHoras"
          type="text"
          value={(ItemHoras && ItemHoras) || ""}
          onChange={(e) => {
            setHoras(e.target.value);
            handleCleanError();
            setActive(true);
          }}
          hasError={horasError} // Aplica a classe de erro se houver erro no nome
        />
        <Input
          ref={praRef} // Referência para o campo Nome
          label="Prazo"
          id="ItenPrazo"
          type="date"
          value={(ItemPrazo && ItemPrazo) || ""}
          onChange={(e) => {
            setPrazo(e.target.value);
            setPrazoError(false);
            setFormError("");
            setError("");
            setActive(true);
          }}
          hasError={prazoError} // Aplica a classe de erro se houver erro no nome
        />

        <Select
          ref={statusRef} // Referência para o campo Status
          label="Status"
          id="ItemStatus"
          options={statusOptions} // Passa o array de status
          value={ItemStatus} // Usa o estado do status selecionado
          onChange={(e) => handleSelectStatus(e)} // Atualiza o status ao selecionar
          hasError={statusError} // Aplica a classe de erro se houver erro no status
        />
        {loading && <Loading />}
        {loading && <p className={styles.loading}>Carregando...</p>}
        {error && <p className={styles.error}>{error}</p>}
        {formError && <p className={styles.error}>{formError}</p>}
        <Button texto="Salvar" onClick={handleSave} active={true} />
        <Button texto="Cancelar" onClick={handleCancel} active={isActive} />
        <Table
          head={head}
          status={statusOptions}
          data={dadosTabela && dadosTabela}
          onClickUpdate={handleClickUpdate}
          onClickDel={handleClickdel}
          listas={{
            fk_UsuId: usersList
          }}
          isMaster={true}
        />
      </div>
    </div>
  );
};

export default Itens;

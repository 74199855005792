import styles from "./Table.module.css";
import {
  inactiveDelIcon,
  inactiveEditIcon,
  delIcon,
  editIcon
} from "../utils/iconsPath";

const Table = ({
  head,
  data,
  status,
  onClickUpdate,
  onClickDel,
  listas,
  isMaster,
  title
}) => {
  if (!data || data.length === 0) {
    return <p className={styles.no_data}>Nenhum dado disponível</p>;
  }

  const getCellAlignmentClass = (columnName, columnValue) => {
    // Verifica se o valor é uma string no formato de data (YYYY-MM-DD)
    const isDateString =
      typeof columnValue === "string" &&
      /^\d{4}-\d{2}-\d{2}$/.test(columnValue);
    // Verifica se o valor é um número ou uma string que representa um número
    const isNumber =
      typeof columnValue === "number" ||
      (typeof columnValue === "string" &&
        /^[0-9]+(\.[0-9]+)?$/.test(columnValue));

    if (columnName.includes("Status") || isDateString) {
      return `${styles.td} ${styles.center}`;
    }

    if (isNumber) {
      return `${styles.td} ${styles.right}`;
    }
    return `${styles.td} ${styles.left}`;
  };

  return (
    <table title={title} alt={title} className={styles.table}>
      <thead>
        <tr className={styles.th}>
          {head.map((column, columnIndex) => (
            <th key={columnIndex}>{column}</th>
          ))}
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => {
          // Verifica se row é um objeto
          if (typeof row !== "object" || row === null) {
            console.error(`Row is not an object: ${row}`);
            return null; // Retorna null se a linha não for válida
          }

          return (
            <tr className={styles.tr} key={rowIndex}>
              {Object.entries(row).map(
                ([columnName, columnValue], columnIndex) => {
                  // console.log("Column:", columnName, "Value:", columnValue);

                  // Inicializa o valor a ser exibido como o valor da coluna
                  let displayValue = columnValue;

                  // Verifica se o nome da coluna é fk_CliId ou fk_GerId
                  if (columnName === "fk_CliId") {
                    const cliente = listas.fk_CliId.find(
                      (cliente) => cliente.CliId === columnValue
                    );

                    displayValue = cliente ? cliente.CliNome : "Desconhecido";
                  } else if (columnName === "fk_GerId") {
                    const gerente = listas.fk_GerId.find(
                      (gerente) => gerente.GerId === columnValue
                    );
                    displayValue = gerente ? gerente.GerNome : "Desconhecido";
                  } else if (columnName === "fk_ProjId") {
                    const projeto = listas.fk_ProjId.find(
                      (projeto) => projeto.ProjId === columnValue
                    );
                    displayValue = projeto ? projeto.ProjNome : "Desconhecido";
                  } else if (columnName === "fk_ModId") {
                    const modulo = listas.fk_ModId.find(
                      (modulo) => modulo.ModId === columnValue
                    );
                    displayValue = modulo ? modulo.ModNome : "Desconhecido";
                  } else if (columnName === "fk_UsuId") {
                    const user = listas.fk_UsuId.find(
                      (user) => user.UsuId === columnValue
                    );
                    displayValue = user ? user.UsuNome : "Desconhecido";
                  }

                  return (
                    <td
                      title={`Coluna ${displayValue}`}
                      className={getCellAlignmentClass(
                        columnName,
                        displayValue
                      )}
                      key={`${rowIndex}-${columnIndex}`}
                    >
                      {columnName.includes("Status")
                        ? status[columnValue] !== undefined
                          ? status[columnValue]
                          : "Desconhecido"
                        : displayValue}
                    </td>
                  );
                }
              )}
              <td className={styles.tdIcon}>
                <img
                  className={styles.icon}
                  src={isMaster ? editIcon : inactiveEditIcon}
                  alt="Editar"
                  title="Editar"
                  onClick={isMaster ? () => onClickUpdate(row) : null}
                />
                <img
                  className={styles.icon} //isMaster ? `${styles.icon} ${styles.active}` : styles.icon
                  src={isMaster ? delIcon : inactiveDelIcon}
                  alt="Deletar"
                  title="Deletar"
                  onClick={isMaster ? () => onClickDel(row) : null}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;

import React, { createContext, useEffect, useState } from "react";

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null); // Inicialize como null em vez de um array vazio
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true); // Mude para true para exibir o carregamento inicialmente
  const [baseUrl, setUrl] = useState("");

  useEffect(() => {
    // Carrega o usuário do localStorage ao montar o AuthProvider
    setLoading(true);
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setUser(storedUser);
    }
    setLoading(false); // Carregamento concluído após verificar o localStorage
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        error,
        setError,
        loading,
        setLoading,
        baseUrl,
        setUrl
      }}
    >
      {!loading && children}
      {/* Exibe as rotas apenas quando o carregamento for concluído */}
    </AuthContext.Provider>
  );
}

import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./NavBtn.module.css";

const NavBtn = ({ title, texto, path, click, classe }) => {
  // console.log(path);
  return (
    <NavLink to={path}>
      <button title={title} alt={title} className={styles.btn} onClick={click}>
        {texto}
      </button>
    </NavLink>
  );
};

export default NavBtn;

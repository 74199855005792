const menuItemsProjeto = [
  {
    title: "< Projetos",
    path: "",
    cName: "close_drop"
  },
  {
    title: "Cadastro",
    path: "/projetos",
    cName: "dropdown_link"
  },
  {
    title: "Modulos",
    path: "/modulos",
    cName: "dropdown_link"
  },
  {
    title: "Gerentes",
    path: "/gerentes",
    cName: "dropdown_link"
  },
  {
    title: "Clientes",
    path: "/clientes",
    cName: "dropdown_link"
  }
];

const menuItemsConsultor = [
  {
    title: "< Consultores",
    path: "",
    cName: "close_drop"
  },
  {
    title: "Cadastro",
    path: "/users",
    cName: "dropdown_link"
  },
  {
    title: "Demandas",
    path: "/itens",
    cName: "dropdown_link"
  },
  {
    title: "Registrar Horas",
    path: "/apontamentos",
    cName: "dropdown_link"
  }
];

const menuItemsPagamentos = [
  {
    title: "< Financeiro",
    path: "",
    cName: "close_drop"
  },
  {
    title: "Valor Hora",
    path: "/valores",
    cName: "dropdown_link"
  },
  {
    title: "Registros Horas",
    path: "/registros",
    cName: "dropdown_link"
  },
  {
    title: "Pagamentos",
    path: "/pagamentos",
    cName: "dropdown_link"
  },
  {
    title: "Extrato",
    path: "/pagos",
    cName: "dropdown_link"
  }
];

export const Menus = {
  projeto: menuItemsProjeto,
  consultor: menuItemsConsultor,
  pagamentos: menuItemsPagamentos
};

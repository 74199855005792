import React, { useEffect, useRef, useState } from "react";
import Input from "../../components/Input/Input";
import styles from "../Pages.module.css";
import Button from "../../components/Button/Button";
import { baseURL } from "../../components/utils/utils";
import { useFetch } from "../../hooks/useFetch";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Registrar = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { httpGet, httpRequest, error, setError, loading } = useFetch(
    `${baseURL}/apontamentos/`
  );
  const { httpGet: httpUsers } = useFetch(`${baseURL}/usuarios/`);
  const { httpGet: httpItens, httpRequest: httoItensRequest } = useFetch(
    `${baseURL}/itens/`
  );
  const { httpRequest: httpValor } = useFetch(`${baseURL}/valores/`);

  const [formError, setFormError] = useState("");
  const [isActive, setActive] = useState(false);
  const [dataError, setDataError] = useState("");
  const [datAtuError, setDatAtuError] = useState("");
  const [horasError, setHorasError] = useState("");

  const { user } = useAuthContext();

  const datRef = useRef();
  const horRef = useRef();
  const datAtuRef = useRef();

  const [UsuNome, setUsuNome] = useState("");
  const [userList, setUsersList] = useState("");

  const [ItemDes, setItemDes] = useState("");
  const [item, setItem] = useState("");
  const [ValHora, setValHora] = useState(0);

  const [apontamento, setApontamento] = useState([]);
  const [ApoId, setId] = useState(0);
  const [fk_UsuId, setUsuId] = useState(0);
  const [fk_ItemId, setItemId] = useState(0);
  const [fk_ValId, setValId] = useState(0);
  const [ApoData, setApoData] = useState();
  const [ApoDatAtu, setApoDatAtu] = useState(0);
  const [ApoQtdHoras, setHoras] = useState(0);
  const [ApoStatus, setStatus] = useState(1);
  const [totApontado, setTotApontado] = useState(0);

  useEffect(() => {
    const getList = async () => {
      if (user && user.token) {
        setUsuId(user.UsuId);
        const apontado = location.state?.apontamento;
        if (apontado && apontado.fk_UsuId) {
          setApontamento(apontado);
          setEditando(apontado);
        }

        const users = await httpUsers(user.token, `listar/`);
        setUsersList(users);

        id && setItemId(Number(id));

        let nItem = location.state?.item;

        if (apontado) {
          nItem = await httpItens(user.token, `listar/${apontado.fk_ItemId}`);
          nItem = nItem[0];
        } else if (!nItem) {
          navigate("/apontamentos");
        }
        setItem(nItem);
        setItemId(nItem.ItemId);
        setItemDes(nItem.ItemDes);
        const horas = await httpRequest(
          nItem,
          user.token,
          `listar2/${nItem.ItemId}`
        );
        const qtdApontado = apontado.ApoQtdHoras;
        setTotApontado(horas.totHoras - qtdApontado);
        const nConsultor = await httpUsers(user.token, `listar/${fk_UsuId}`);
        setUsuNome(nConsultor && nConsultor[0].UsuNome);
        setApoData(getTodayDate());
        setApoDatAtu(getTodayDate());
        retValorHora();
      } else {
        navigate("/login");
      }
    };
    getList();
  }, []);

  const setEditando = (editando) => {
    if (editando) {
      setApontamento(editando);
      setId(editando.ApoId);
      setUsuId(editando.fk_UsuId);
      setItemId(editando.fk_ItemId);
      setValId(editando.fk_ValId);
      setApoData(editando.ApoData);
      setApoDatAtu(editando.ApoDatAtu);
      setHoras(editando.ApoQtdHoras);
      setStatus(editando.ApoStatus);
      setActive(true);
    }
  };
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Os meses começam em 0
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const retValorHora = async () => {
    const body = {};
    body.ValDatIni = getTodayDate();

    const valor = await httpValor(
      body,
      user.token,
      `ultimoValor/${user.UsuId}`
    );

    if (valor && valor[0].ValId) {
      setValHora(valor[0].ValVal1);
      setValId(valor[0].ValId);
      setFormError(false);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const nApontamento = await validaCampos();
    if (nApontamento) {
      var endpoint = "inserir";
      if (ApoId > 0) {
        endpoint = `atualizar/${ApoId}`;
      }
      const data = await httpRequest(nApontamento, user.token, endpoint);
      if (data) {
        setItem(data);
        handleCancel();
      }
      retStatus(data);
    }
  };
  // valida os campos do formulário
  const validaCampos = async () => {
    if (!ApoQtdHoras || isNaN(parseFloat(ApoQtdHoras))) {
      setFormError("O campo Horas é obrigatório.");
      setHorasError(true);
      horRef.current.focus(); // Foca no campo Nome
      return false;
    }

    if (!ApoData) {
      setFormError("O campo Data é obrigatório.");
      setDataError(true);
      datRef.current.focus(); // Foca no campo data
      return false;
    }
    if (!ApoDatAtu) {
      setFormError("O campo Data é obrigatório.");
      setDatAtuError(true);
      datAtuRef.current.focus(); // Foca no campo data
      return false;
    }
    retValorHora();

    const nApontamento = {
      ApoDatAtu,
      ApoData,
      ApoId,
      ApoQtdHoras,
      ApoStatus,
      fk_UsuId,
      fk_ValId,
      fk_ItemId
    };
    return nApontamento;
  };

  const handleCancel = () => {
    setActive(false); // Desativa o botão "Cancelar"
    setId(0);
    setValId(0);
    setApoData(0);
    setApoDatAtu(0);
    setHoras(0);
    setStatus(1);
    setValHora(0);
    handleCleanError();
    //  navigate("/apontamentos");
  };
  const handleCleanError = () => {
    setFormError("");
    setError("");
  };
  const retStatus = async () => {
    const hoje = new Date();
    hoje.setHours(0, 0, 0, 0);
    const prazo = new Date(item.ItemPrazo);
    const percent = ((totApontado + ApoQtdHoras) / item.ItemHoras) * 100;

    let status = 0;

    if (percent === 100) {
      status = 3; //"Concluído";
    } else if (hoje <= prazo && percent === 0) {
      status = 1; //"Pendente"; // Se a data do item for hoje ou anterior, está "Em dia"
    } else if (hoje <= prazo && percent < 100 && percent > 0) {
      status = 2; //"Em andamento";
    } else if (hoje > prazo && percent < 100) {
      status = 4; //"Atrasado";
    }
    item.ItemStatus = status;

    const d = await httoItensRequest(
      item,
      user.token,
      `atualizar/${item.ItemId}`
    );
    console.log(d);
    return true;
  };

  const retornaStatus = () => {
    if (ApoStatus === 1) {
      return "Pendente";
    } else if (ApoStatus === 2) {
      return "Pago";
    } else if (ApoStatus === 3) {
      return "Confirmado";
    }
  };
  const decimalToTime = (decimal) => {
    if (decimal == null) return "";
    const hours = Math.floor(decimal); // Parte das horas
    const minutes = Math.floor((decimal - hours) * 60); // Parte dos minutos sem arredondar
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  const timeToDecimal = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const horas = hours + minutes / 60;
    return horas;
  };
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Apontamentos</h1>
      <div className={styles.card}>
        <Input
          label="Usuário"
          type="text"
          value={(UsuNome && UsuNome) || ""}
          disabled={true}
        />
        <Input
          label="Demanda"
          type="text"
          value={(ItemDes && ItemDes) || ""}
          disabled={true}
        />

        <Input
          ref={datRef} // Referência para o campo Nome
          label="Data apontamento"
          id="ApoData"
          type="date"
          value={(ApoData && ApoData) || ""}
          onChange={(e) => {
            setApoData(e.target.value);
            setApoDatAtu(e.target.value);
            setDataError(false);
            handleCleanError();
            setActive(true);
          }}
          hasError={dataError} // Aplica a classe de erro se houver erro no nome
        />

        <Input
          ref={datAtuRef} // Referência para o campo Nome
          label="Data atendimento"
          id="ApoDatAtu"
          type="date"
          value={(ApoDatAtu && ApoDatAtu) || ""}
          onChange={(e) => {
            setApoDatAtu(e.target.value);
            setDatAtuError(false);
            handleCleanError();
            setActive(true);
          }}
          hasError={datAtuError} // Aplica a classe de erro se houver erro no nome
        />
        <Input
          ref={horRef} // Referência para o campo Nome
          label="Horas"
          id="ApoQtdHoras"
          type="time"
          value={ApoQtdHoras != null ? decimalToTime(ApoQtdHoras) : ""} // Converte o valor decimal para o formato de hora
          onChange={(e) => {
            setHoras(timeToDecimal(e.target.value)); // Atualiza o valor em decimal no estado
            handleCleanError();
            setActive(true);
          }}
          hasError={horasError} // Aplica a classe de erro se houver erro no nome
        />
        <Input
          label="Status"
          type="text"
          value={retornaStatus()}
          disabled={true}
        />
        {loading && <p className={styles.loading}>Carregando...</p>}
        {formError && <p className={styles.error}>{formError}</p>}
        {error && <p className={styles.error}>{error}</p>}
        <Button
          texto="Salvar"
          onClick={handleSave}
          active={ValHora ? true : false}
        />
        <Button texto="Cancelar" onClick={handleCancel} active={isActive} />
      </div>
    </div>
  );
};

export default Registrar;

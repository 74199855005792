import { useEffect, useState } from "react";
import axios from "axios";
import { useAuthContext } from "./useAuthContext";

// Custom hook para fetch
export const useFetch = (baseUrl) => {
  const { user } = useAuthContext();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState();
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(user && user.token);
  }, [baseUrl]);

  const httpRequest = (body, token, endpoint) => {
    return httpRequestWithMethod("POST", endpoint, { token, body });
  };
  const httpGet = (token, endpoint) => {
    return httpRequestWithMethod("GET", endpoint, { token });
  };

  const httpRequestWithMethod = async (
    method,
    endpoint,
    { body = null, token = null } = {}
  ) => {
    const msgError = `Erro desconhecido ao fazer a requisição ${baseUrl}${endpoint}`;
    setLoading(true); // Define o estado de carregamento
    //  console.log(endpoint);
    const startTime = Date.now(); // Captura o tempo no início da requisição

    try {
      const config = {
        method, // Método HTTP (GET, POST, etc.)
        credentials: "include",
        url: baseUrl + endpoint, // URL completa
        headers: {
          "Content-Type": "application/json",
          ...(token && { Authorization: `Bearer ${token}` }) // Inclui o token JWT se houver
        },
        ...(body && { data: body }) // Inclui o corpo da requisição, se existir
      };

      const response = await axios(config); // Faz a requisição usando Axios
      const endTime = Date.now(); // Captura o tempo ao receber a resposta
      const duration = endTime - startTime;

      if (duration > 1000) {
        setError(
          `A requisição ${method} para ${endpoint} demorou ${duration} ms`
        );
      }

      const data = response.data;
      //console.log(data);
      if (!data.success) {
        console.log(data);
        setError(data.message || msgError);
        setLoading(false);
        return data.success;
      }
      setLoading(false);
      // Retorna os dados da resposta
      return data.data;
    } catch (error) {
      // Captura erros de requisição ou de validação

      const errorMessage = error.response
        ? `${error.response.status} - ${error.response.data.message}`
        : error.message || msgError;

      setError(errorMessage);
      setLoading(false);
    }
  };

  return {
    httpRequest,
    setError,
    error,
    loading,
    httpGet
  };
};

import React, { forwardRef } from "react";
import styles from "./Input.module.css";

const Input = forwardRef(
  (
    { id, label, type, value, onChange, disabled, hasError, autoComplete },
    ref
  ) => {
    return (
      <div className={`${styles.input_container}`}>
        <label
          className={
            type === "password"
              ? `${styles.input_label} ${styles.password_label}`
              : `${styles.input_label}`
          }
          htmlFor={id}
        >
          {label}
          <input
            alt={label}
            title={label}
            ref={ref}
            id={id}
            type={type}
            value={value}
            onChange={onChange}
            disabled={disabled}
            autoComplete={autoComplete}
            className={
              type === "number"
                ? `${styles.input} ${styles.right}  ${
                    hasError ? styles.error : ""
                  }`
                : `${styles.input} ${hasError ? styles.error : ""}`
            }
          />
        </label>
      </div>
    );
  }
);

export default Input;

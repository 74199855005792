import React from "react";
import styles from "./Loading.module.css";

const Loading = () => {
  return (
    <div id="loader" className={styles.loader}>
      <img id="loader_icon" src="./loading.svg" alt="" />
    </div>
  );
};

export default Loading;

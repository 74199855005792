import React, { useEffect, useRef, useState } from "react";
import Input from "../../components/Input/Input";
import styles from "../Pages.module.css";
import Select from "../../components/Select/Select";
import Button from "../../components/Button/Button";
import { baseURL } from "../../components/utils/utils";
import { useFetch } from "../../hooks/useFetch";
import { useAuthContext } from "../../hooks/useAuthContext";
import Table from "../../components/Table/Table";
import { useNavigate } from "react-router-dom";

const Clientes = () => {
  const { httpGet, httpRequest, setError, error, loading } = useFetch(
    `${baseURL}/clientes/`
  );
  // Refs para os campos de inputuse

  const nomeRef = useRef(null);
  const statusRef = useRef(null);

  const { user } = useAuthContext();
  const [CliId, setId] = useState("");
  const [CliNome, setNome] = useState("");
  const [CliStatus, setStatus] = useState(0);
  const [cliente, setCliente] = useState({});
  const [data, setData] = useState([]);
  const [formError, setFormError] = useState(null);
  const [isActive, setActive] = useState(false);

  const [nomeHasError, setNomeHasError] = useState(false);
  const [statusHasError, setStatusHasError] = useState(false);

  const head = ["Id", "Nome", "Status"];
  const options = ["...", "Inativo", "Ativo"];
  const navigate = useNavigate();
  useEffect(() => {
    const getList = async () => {
      if (user && user.token) {
        const clientes = await httpGet(user.token, "listar");
        setData(clientes);
      } else {
        navigate("/login");
      }
    };
    getList();
  }, [cliente]);

  const handleSave = async (e) => {
    e.preventDefault();
    setActive(true);
    const nCliente = validaCampos();
    if (nCliente) {
      var endpoint = "inserir";
      if (CliId > 0) {
        endpoint = `atualizar/${CliId}`;
      }
      if (CliNome !== "") {
        const data = await httpRequest(nCliente, user.token, endpoint);
        if (data) {
          setCliente(data);
          handleCancel();
        }
      }
    }
  };

  const handleClickdel = async (cliente) => {
    setActive(true);
    const success = await httpRequest(
      cliente,
      user.token,
      `remover/${cliente.CliId}`
    );
    if (success) {
      setCliente(data);
      handleCancel();
    }
  };
  const handleCancel = () => {
    setActive(false); // Desativa o botão "Cancelar"
    setId("");
    setNome("");
    setStatus(0); // Atualiza CliStatus para o estado inicial
    handleCleanError();
  };
  const handleCleanError = () => {
    setFormError("");
    setError("");
    setNomeHasError("");
    setStatusHasError("");
  };

  const handleClickUpdate = (cliente) => {
    setId(cliente.CliId);
    setNome(cliente.CliNome);
    setStatus(cliente.CliStatus);
    setCliente(cliente);
    setActive(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const validaCampos = () => {
    // Valida o campo Nome
    if (!CliNome || CliNome.trim() === "") {
      setFormError("O campo Nome é obrigatório.");
      setNomeHasError(true);
      nomeRef.current.focus(); // Foca no campo Nome
      return;
    }

    // Valida o campo Status
    if (CliStatus === 0 || CliStatus === "...") {
      setFormError("O campo Status é obrigatório.");
      setStatusHasError(true);
      statusRef.current.focus(); // Foca no campo Status
      return;
    }
    const nCliente = {
      CliId,
      CliNome,
      CliStatus
    };
    return nCliente;
  };

  const handleSelect = (e) => {
    setFormError("");
    setError("");
    const newStatus = e.target.value;
    setStatus(newStatus);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Clientes</h1>
      <div className={styles.card}>
        <Input
          ref={nomeRef} // Referência para o campo Nome
          label="Nome"
          id="CliNome"
          type="text"
          value={(CliNome && CliNome) || ""}
          onChange={(e) => {
            setNome(e.target.value);
            setNomeHasError(false);
            handleCleanError();
          }}
          hasError={nomeHasError} // Aplica a classe de erro se houver erro no nome
        />

        <Select
          ref={statusRef} // Referência para o campo Status
          label="Status"
          id="CliStatus"
          options={options} // Passa o array de status
          value={CliStatus} // Usa o estado do status selecionado
          onChange={(e) => (handleSelect(e), setStatusHasError(false))} // Atualiza o status ao selecionar
          hasError={statusHasError} // Aplica a classe de erro se houver erro no status
        />
        {loading && <p className={styles.loading}>Carregando...</p>}
        {error && <p className={styles.error}>{error}</p>}
        {formError && <p className={styles.error}>{formError}</p>}
        <Button texto="Salvar" onClick={handleSave} active={true} />
        <Button texto="Cancelar" onClick={handleCancel} active={isActive} />

        <Table
          head={head}
          status={options}
          data={data && data}
          onClickUpdate={handleClickUpdate}
          onClickDel={handleClickdel}
          isMaster={user && user.fk_NivId > 2 ? false : true}
        />
      </div>
    </div>
  );
};

export default Clientes;

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Login.module.css";
import Input from "../../components/Input/Input";
import { useFetch } from "../../hooks/useFetch";
import { baseURL } from "../../components/utils/utils";
import Button from "../../components/Button/Button";
import { useData } from "../../hooks/useData";
import { useAuthContext } from "../../hooks/useAuthContext";
import Loading from "../../components/Loading/Loading";

const NovaSenha = () => {
  const navigate = useNavigate();
  const { data: nUser, updateData } = useData();
  const { httpRequest, error, setError, loading } = useFetch(
    `${baseURL}/usuarios/`
  );
  const senhaRef = useRef(null);
  const confRef = useRef(null);
  const [senhaError, setSenhaError] = useState(false);
  const [confError, setConfError] = useState(false);
  const [formError, setFormError] = useState("");
  const [senha, setSenha] = useState("");
  const [confSenha, setConfSenha] = useState("");
  const { user } = useAuthContext();

  useEffect(() => {
    if (user && user.token && user !== null) {
      //navigate("/");
    }
  }, [user]);

  //************* */
  const handleSave = async () => {
    var endpoint = `alterarsenha`;
    var data = {};
    if (nUser && nUser.UsuEmail) {
      if (validaCampos()) {
        data = await httpRequest(nUser, user.token, endpoint);
      }
    } else if (validaCampos()) {
      data = await httpRequest(user, user.token, endpoint);

      if (data) {
        localStorage.setItem("user", JSON.stringify(data));

        endpoint = `atualizar/${user.UsuId}`;
        user.UsuStatus = 1;
        data = await httpRequest(nUser, user.token, endpoint);
        handleCancel();
      }
    }
    updateData(data);
    navigate("/users");
  };

  const handleCleanError = async () => {
    setFormError(false);
    setError(false);
  };
  const handleCancel = async () => {
    setSenha("");
    setConfSenha("");
    navigate("/users");
  };

  const validaCampos = () => {
    if (senha === "") {
      setFormError("É necessário informar uma senha!");
      setSenhaError(true);
      senhaRef.current.focus(); // Foca no campo Nome
      return false;
    }
    if (confSenha === "") {
      setFormError("Confirme sua senha!");
      setConfError(true);
      confRef.current.focus(); // Foca no campo Nome
      return false;
    }

    if (senha === confSenha) {
      if (nUser) {
        nUser.UsuSenha = senha;
        return nUser;
      } else {
        user.UsuSenha = senha;
      }
    } else {
      setFormError("As senhas não conferem");
      setConfError(true);
      return false;
    }
    return true;
  };
  return (
    <div className={styles.container_login}>
      <h1 className={styles.title}>Login</h1>
      <div className={styles.content}>
        {loading && <Loading />}
        <Input
          ref={senhaRef}
          label="Senha"
          id="senha"
          type="password"
          placeholder="Digite sua Senha"
          value={senha}
          onChange={(e) => [
            setSenha(e.target.value),
            handleCleanError(),
            setSenhaError(false)
          ]}
          hasError={senhaError}
          autoComplete="new-password"
        />
        <Input
          ref={confRef}
          label="Confirme a senha"
          id="confSenha"
          type="password"
          placeholder="Confirmar Senha"
          value={confSenha}
          onChange={(e) => [
            setConfSenha(e.target.value),
            handleCleanError(),
            setConfError(false)
          ]}
          hasError={confError}
          autoComplete="new-password"
        />

        {loading && <p className={styles.loading}>Carregando...</p>}
        {error && <p className={styles.error}>{error}</p>}
        {formError && <p className={styles.error}>{formError}</p>}
        <Button texto="Salvar" onClick={handleSave} active={true} />
        <Button texto="Cancelar" onClick={handleCancel} active={true} />
      </div>
    </div>
  );
};

export default NovaSenha;

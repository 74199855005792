import React, { useEffect, useState } from "react";

import styles from "./Header.module.css";
import { useAuthContext } from "../../hooks/useAuthContext";
import { baseURL } from "../utils/utils";
import { useNavigate } from "react-router-dom";
const Header = () => {
  const navigate = useNavigate();
  const logo = `${baseURL}/avatar/TAG_Logo2.png`;
  const [avatarImg, setAvatar] = useState(`${baseURL}/avatar/avatar.png`);
  const { user } = useAuthContext();

  useEffect(() => {
    //console.log("Header: ", user);
    if (user) {
      // console.log(user.UsuAvatar);
      setAvatar(user.UsuAvatar);
    } else {
      setAvatar(`${baseURL}/avatar/avatar.png`);
      navigate("/login");
    }
    // console.log(user.UsuAvatar);
    //console.log(avatarImg);
  }, [user]);

  const handleEditProfile = () => {
    navigate("/perfil");
  };
  return (
    <header className={styles.header}>
      <div
        className={styles.logoContainer}
        title="Logomarca da empresa"
        alt="Logomarca da empresa"
      >
        <img src={logo} alt="Logo" className={styles.logo} />
      </div>

      <div
        className={styles.centerHeader}
        title="Nome do sistema Demand Manager"
        alt="Nome do sistema Demand Manager"
      >
        <h1 className={styles.systemName}>Demand Manager</h1>
      </div>

      <div className={styles.avatarContainer}>
        <img
          alt="Avatar do usuário"
          title="Avatar do usuário"
          onClick={handleEditProfile}
          src={user ? user.UsuAvatar : avatarImg}
          className={styles.avatar}
        />
        <p
          alt={`Usuário ${user ? user.UsuNome : "Não logado"}`}
          title={`Usuário ${user ? user.UsuNome : "Não logado"}`}
          className={styles.usu_nome}
          onClick={handleEditProfile}
        >
          {user ? user.UsuNome : "Usuário"}
        </p>
      </div>
    </header>
  );
};

export default Header;

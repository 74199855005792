import React, { useState } from "react";
import NavBtn from "./NavBtn";
import { NavLink, useNavigate } from "react-router-dom";
import "./NavBar.css";
import Dropdown from "./Dropdown";
import { useAuthContext } from "../../hooks/useAuthContext";

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdownProjetos, setDropdownProjetos] = useState(false);
  const [dropdownConsultores, setDropdownConsultores] = useState(false);
  const [dropdownFinanceiro, setDropdownFinanceiro] = useState(false);
  const { user, setUser } = useAuthContext();
  const navigate = useNavigate();
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const logout = () => {
    localStorage.removeItem("user");
    setUser(null);
  };

  const handleLogin = () => {
    closeMobileMenu();
    navigate("/login");
  };
  // Função para fechar todos os dropdowns
  const closeAllDropdowns = () => {
    setDropdownProjetos(false);
    setDropdownConsultores(false);
    setDropdownFinanceiro(false);
  };

  // Função para alternar a abertura/fechamento dos dropdowns e manter o menu aberto
  const toggleDropdown = (menu) => {
    closeAllDropdowns(); // Fecha todos antes de abrir o novo
    if (menu === "projeto") {
      setDropdownProjetos(!dropdownProjetos);
    } else if (menu === "consultor") {
      setDropdownConsultores(!dropdownConsultores);
    } else if (menu === "pagamentos") {
      setDropdownFinanceiro(!dropdownFinanceiro);
    }
  };

  // Funções de mouse leave
  const onMouseLeaveProjetos = () => {
    if (window.innerWidth > 700) {
      const id = setTimeout(() => {
        setDropdownProjetos(false);
      }, 150);
      setTimeoutId(id);
    }
  };

  const onMouseLeaveConsultores = () => {
    if (window.innerWidth > 700) {
      const id = setTimeout(() => {
        setDropdownConsultores(false);
      }, 150);
      setTimeoutId(id);
    }
  };

  const onMouseLeaveFinanceiro = () => {
    if (window.innerWidth > 700) {
      const id = setTimeout(() => {
        setDropdownFinanceiro(false);
      }, 150);
      setTimeoutId(id);
    }
  };

  return (
    <>
      <nav className="navbar">
        {/**<NavLink
          to="/"
          className="navbar-logo"
          alt="Ir para página inicial"
          title="Ir para página inicial"
          onClick={closeMobileMenu}
        >
          Home
        </NavLink> */}
        {
          /**Menu sanduiche */
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
        }

        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li
            className="nav-item"
            onMouseLeave={onMouseLeaveProjetos}
            onClick={() => toggleDropdown("projeto")}
          >
            <NavLink
              alt="Menu projetos"
              title="Menu projetos"
              className="nav-links"
            >
              Projetos &nbsp;
              <i className="fas fa-caret-down" />
            </NavLink>
            {dropdownProjetos && (
              <Dropdown onClick={closeMobileMenu} menu="projeto" />
            )}
          </li>

          <li
            className="nav-item"
            onMouseLeave={onMouseLeaveConsultores}
            onClick={() => toggleDropdown("consultor")}
          >
            <NavLink
              alt="Menu consultores"
              title="Menu consultores"
              className="nav-links"
            >
              Consultores &nbsp;
              <i className="fas fa-caret-down" />
            </NavLink>
            {dropdownConsultores && (
              <Dropdown onClick={closeMobileMenu} menu="consultor" />
            )}
          </li>

          <li
            className="nav-item"
            onMouseLeave={onMouseLeaveFinanceiro}
            onClick={() => toggleDropdown("pagamentos")}
          >
            <NavLink
              alt="Menu financeiro"
              title="Menu financeiro"
              className="nav-links"
            >
              Financeiro &nbsp;
              <i className="fas fa-caret-down" />
            </NavLink>
            {dropdownFinanceiro && (
              <Dropdown onClick={closeMobileMenu} menu="pagamentos" />
            )}
          </li>

          <li className="menu-space">
            <NavLink
              alt="Botão sair"
              title="Botão sair"
              placeholder="botão sair"
              to={!user ? "/login" : "/home"}
              className="nav-links-mobile"
              onClick={!user ? closeMobileMenu : logout}
            >
              {user ? "Sair" : "Entrar"}
            </NavLink>
          </li>
        </ul>
        <NavBtn
          title={user ? "Botão sair" : " Botão entrar"}
          texto={user ? "Sair" : "Entrar"}
          path={user ? "/home" : "/login"}
          click={!user ? closeMobileMenu : logout}
        />
      </nav>
    </>
  );
}

export default Navbar;

import React, { useEffect, useRef, useState } from "react";
import styles from "../Pages.module.css";
import tableStyles from "../../components/Table/Table.module.css";
import { baseURL } from "../../components/utils/utils";
import { useFetch } from "../../hooks/useFetch";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import {
  editIcon,
  delIcon,
  inactiveEditIcon
} from "../../components/utils/iconsPath";

const Apontamentos = () => {
  const navigate = useNavigate();
  const { httpGet, httpRequest, error, setError, loading } = useFetch(
    `${baseURL}/apontamentos/`
  );
  const { httpGet: httpUsers } = useFetch(`${baseURL}/usuarios/`);
  const { httpGet: httpItens } = useFetch(`${baseURL}/itens/`);

  const { user } = useAuthContext();

  const statusOptions = [
    "...",
    "Pendente",
    "Em andamento",
    "Concluído",
    "Atrasado"
  ];

  const [usersList, setUsersList] = useState([]);
  const [itensList, setItensList] = useState([]);

  const [dadosTabela, setTabela] = useState([]);
  const [data, setData] = useState([]);

  //dados do formulário
  const [apontamento, setApontamento] = useState([]);

  // Effects de retorno dos dados:
  useEffect(() => {
    const getList = async () => {
      if (user && user.token) {
        const usuarios = await httpUsers(user.token, "listar");
        setUsersList(usuarios); // Armazena os usuarios
        const itens = await httpItens(user.token, "listar");
        setItensList(itens); // Armazena os Itens de projeto
        itens && setTabela(itens);
        const dados = await httpGet(user.token, "listar");
        if (dados && dados) {
          setData(dados);
        }
      } else {
        navigate("/login");
      }
    };
    getList();
  }, [apontamento]);
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, []);

  const retPercentual = (item) => {
    const percentual = (retTotalApontado(item) / item.ItemHoras) * 100;
    return `${percentual.toFixed(2)} %`;
  };

  const retStatus = (item) => {
    // statusOptions[item.ItemStatus];
    return statusOptions[item.ItemStatus] ?? "Status inválido";
    /*switch (item.ItemStatus) {
      case 1:
        return "Pendente";
      case 2:
        return "Em andamento";
      case 3:
        return "Concluído";
      case 4:
        return "Atrasado";
      default:
        return "Pendente";
    }*/

    return;
  };

  const retUserName = (item) => {
    const consultor = usersList.find(
      (cons) => cons.UsuId === Number(item.fk_UsuId)
    );
    return consultor.UsuNome;
  };

  const retTotalApontado = (item) => {
    let horas = 0;
    if (data) {
      data.map((dado) => {
        if (item && item.ItemId === dado.fk_ItemId) horas += dado.ApoQtdHoras;
      });
    }
    return horas;
  };
  function decimalToHours(decimal) {
    const hours = Math.floor(decimal); // Parte inteira: horas
    const minutes = Math.round((decimal - hours) * 60); // Parte decimal convertida em minutos
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`; // Formatar como hh:mm
  }

  const onClickPointment = (item) => {
    navigate(`/registrar`, { state: { item } });
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Apontamentos</h1>
      <div className={styles.card}>
        {loading && <p className={styles.loading}>Carregando...</p>}
        {error && <p className={styles.error}>{error}</p>}

        <table className={tableStyles.table}>
          <thead>
            <tr className={tableStyles.tr}>
              <th className={tableStyles.th}>Consultor</th>
              <th className={tableStyles.th}>Tarefa</th>
              <th className={tableStyles.th}>Prazo</th>
              <th className={tableStyles.th}>Horas Prev</th>
              <th className={tableStyles.th}>Apontado</th>
              <th className={tableStyles.th}>Concluido</th>
              <th className={tableStyles.th}>Status</th>
              <th className={tableStyles.th}>Apontar</th>
            </tr>
          </thead>
          <tbody>
            {dadosTabela &&
              dadosTabela.map((row) => {
                return (
                  <tr className={tableStyles.tr} key={row.ItemId}>
                    <td className={tableStyles.td}>{retUserName(row)}</td>
                    <td className={tableStyles.td}>{row.ItemDes}</td>
                    <td className={`${tableStyles.td} ${tableStyles.center}`}>
                      {row.ItemPrazo}
                    </td>
                    <td className={`${tableStyles.td} ${tableStyles.center}`}>
                      {row.ItemHoras}
                    </td>
                    <td className={`${tableStyles.td} ${tableStyles.center}`}>
                      {decimalToHours(retTotalApontado(row))}
                    </td>
                    <td className={`${tableStyles.td} ${tableStyles.right}`}>
                      {retPercentual(row)}
                    </td>
                    <td className={`${tableStyles.td} ${tableStyles.center}`}>
                      {retStatus(row)}
                    </td>
                    <td className={tableStyles.tdIcon}>
                      <img
                        className={tableStyles.icon}
                        src={
                          user.UsuId === row.fk_UsuId
                            ? editIcon
                            : inactiveEditIcon
                        }
                        alt="Registrar horas"
                        title="Registrar horas"
                        onClick={
                          user.UsuId === row.fk_UsuId
                            ? () => onClickPointment(row)
                            : null
                        }
                      />
                      {user.UsuId}
                      {row.fk_UsuId}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Apontamentos;

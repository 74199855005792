import styles from "../Pages.module.css";
//import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useFetch } from "../../hooks/useFetch";
import { useData } from "../../hooks/useData";
import { useEffect, useRef, useState } from "react";

import { baseURL } from "../../components/utils/utils";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Select from "../../components/Select/Select";
import Table from "../../components/Table/Table";
import SelectObjetos from "../../components/Select/SelectObjetos";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import { avatarIco } from "../../components/utils/iconsPath";

const Users = () => {
  const navigate = useNavigate();
  const { updateData } = useData();
  const { httpGet, httpRequest, error, setError, loading } = useFetch(
    `${baseURL}/usuarios/`
  );
  const { httpGet: httpGetNiveis } = useFetch(`${baseURL}/niveis/`);
  const { user, setUser } = useAuthContext();

  const nomeRef = useRef(null);
  const avatarRef = useRef(null);
  const mailRef = useRef(null);
  const statusRef = useRef(null);
  const nivelRef = useRef(null);

  const [data, setData] = useState();
  const [usuario, setUsuario] = useState("");
  const [dadosTabela, setTabela] = useState([]);
  const [niveis, setNiveis] = useState([]);
  const [statusError, setStatusError] = useState("");
  const [nomeHasError, setNomeHasError] = useState("");

  const [nivelError, setNivelError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [avatarError, setAvatarError] = useState("");
  // const [nomeError, setNomeError] = useState("");
  const [formError, setFormError] = useState("");
  const [isActive, setActive] = useState(false);
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);

  const [UsuId, setId] = useState("");
  const [UsuNome, setNome] = useState("");
  const [UsuEmail, setEmail] = useState("");
  const [UsuPix, setPix] = useState("");
  const [fk_NivId, setNivel] = useState(3);
  const [UsuAvatar, setAvatar] = useState(avatarIco);
  const [UsuStatus, setStatus] = useState(2);

  const UsuTry = 0;
  const UsuSenha = 123;

  const options = ["...", "Inativo", "Ativo"];
  const head = ["Id", "Nome", "E-mail", "Pix", "Status"];

  useEffect(() => {
    const carregaDados = async () => {
      const niveisData = await httpGetNiveis(null, "listar");
      setNiveis(niveisData);
      if (user && user.token) {
        const niveisData = await httpGetNiveis(user.token, "listar");
        setNiveis(niveisData);
        const dados = await httpGet(user.token, "listar");
        const dadosTabela = objToList(dados);
        dadosTabela && setTabela(dadosTabela);
        setData(dados);
        if (user && user.fk_NivId > 2) {
          // handleClickUpdate(user && user);
        }
      }
    };
    carregaDados();
  }, [usuario]);

  const objToList = (dados) => {
    return (
      dados &&
      dados.map((obj) =>
        Object.keys(obj).reduce((acc, key) => {
          if (
            key === "UsuId" ||
            key === "UsuNome" ||
            key === "UsuEmail" ||
            key === "UsuPix" ||
            key === "UsuStatus"
          ) {
            acc[key] = obj[key];
          }
          return acc;
        }, {})
      )
    );
  };

  const handleSelectNivel = (e) => {
    handleCleanError();
    setNivelError(false);
    setNivel(e.target.value); // Atualiza GerStatus
    //console.log(e.target.value);
  };

  const handleSelectStatus = (e) => {
    handleCleanError();
    setStatusError(false);
    setStatus(e.target.value);
  };
  const handleClickUpdate = (usuEditing) => {
    const eUser = data && data.find((user) => user.UsuId === usuEditing.UsuId);

    setId(eUser.UsuId);
    setNome(eUser.UsuNome);
    setStatus(eUser.UsuStatus);
    setNivel(eUser.fk_NivId);
    setEmail(eUser.UsuEmail);
    setPix(eUser.UsuPix);
    setAvatar(eUser.UsuAvatar);
    setIsEmailDisabled(true);
    setUsuario(eUser);
    setActive(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleClickdel = async (usuDeleting) => {
    setActive(true);
    const success = await httpRequest(
      usuDeleting,
      user.token,
      `remover/${usuDeleting.UsuId}`
    );
    if (success) {
      setUsuario(data);
      handleCancel();
    }
  };

  const handleCancel = () => {
    setActive(false); // Desativa o botão "Cancelar"
    setId("");
    setNome("");
    setPix(0);
    setStatus(0); // Atualiza CliStatus para o estado inicial
    setNivel(0);
    setAvatar("");
    setEmail("");
    handleCleanError();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSave = async () => {
    const nUser = validaCampos();
    if (nUser.UsuId <= 0) {
      var endpoint = "registrar";
      const data = await httpRequest(nUser, null, endpoint);
      if (data) {
        login(nUser);
        navigate("/senha");
      }
    } else if (nUser.UsuId > 0) {
      endpoint = `atualizar/${nUser.UsuId}`;
      const data = await httpRequest(nUser, user.token, endpoint);

      if (data) {
        setUsuario(data);
        handleCancel();
      }
    }
  };
  const handleAlterarSenha = async () => {
    const nUser = validaCampos();
    if (user.fk_NivId === 1) {
      updateData(nUser);
      navigate("/senha");
    } else if (user && nUser && user.UsuId === nUser.UsuId) {
      updateData(user);
      navigate("/senha");
    } else {
      setFormError("Usuário precisa estar logado pra alterar a senha");
    }
  };

  const login = async (user) => {
    const data = await httpRequest(user, null, "login");
    if (data) {
      localStorage.setItem("user", JSON.stringify(data));

      setUser(data);
    }
  };

  const validaCampos = () => {
    // Valida o campo Nome
    if (!UsuNome || UsuNome.trim() === "") {
      setFormError("O campo Nome é obrigatório.");
      setNomeHasError(true);
      nomeRef.current.focus(); // Foca no campo Nome
      return;
    }
    // Valida o campo Email
    if (!UsuEmail || UsuEmail.trim() === "" || !isEmail(UsuEmail)) {
      setFormError("Informe um endereço de e-mail válido.");
      setEmailError(true);
      mailRef.current.focus(); // Foca no campo Nome
      return;
    }
    // Valida o campo Nivel
    // console.log(fk_NivId);
    if (fk_NivId === 0 || !fk_NivId) {
      setFormError("Selecione um Nível.");
      setNivelError(true);
      nivelRef.current.focus(); // Foca no campo Status
      return;
    }
    // Valida o campo Status
    if (UsuStatus === 0) {
      setFormError("O campo Status é obrigatório.");
      setStatusError(true);
      statusRef.current.focus(); // Foca no campo Status
      return;
    }
    //validate image url
    try {
      new URL(UsuAvatar);
    } catch (error) {
      setAvatarError(true);
      setFormError("A imagem precisa ser uma URL válida.");
      avatarRef.current.focus(); // Foca no campo Status
      return;
    }
    const nUser = {
      UsuId,
      UsuNome,
      UsuEmail,
      UsuPix,
      UsuAvatar,
      fk_NivId,
      UsuStatus,
      UsuTry,
      UsuSenha
    };
    return nUser;
  };

  const isEmail = (value) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(value);
  };

  const handleCleanError = () => {
    setFormError("");
    setError("");
  };
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        {user && user.fk_NivId <= 2 ? "Consultores" : "Meus dados"}
      </h1>
      <div className={styles.card}>
        {loading && <Loading />}
        <Input
          ref={nomeRef} // Referência para o campo Nome
          label="Nome"
          id="UsuNome"
          type="text"
          value={(UsuNome && UsuNome) || ""}
          onChange={(e) => {
            setNome(e.target.value);
            setNomeHasError(false);
            handleCleanError();
          }}
          hasError={nomeHasError} // Aplica a classe de erro se houver erro no nome
        />
        <Input
          ref={mailRef} // Referência para o campo Nome
          label="Email"
          id="UsuEmail"
          type="text"
          value={(UsuEmail && UsuEmail) || ""}
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError(false);
            handleCleanError();
          }}
          disabled={isEmailDisabled}
          hasError={emailError} // Aplica a classe de erro se houver erro no nome
        />
        <Input
          label="Chave Pix"
          id="UsuPix"
          type="text"
          value={(UsuPix && UsuPix) || ""}
          onChange={(e) => {
            setPix(e.target.value);
            setNomeHasError(false);
            handleCleanError();
          }}
          hasError={nomeHasError} // Aplica a classe de erro se houver erro no nome
        />
        <SelectObjetos
          ref={nivelRef} // Referência para o campo Status
          label="Nivel"
          id="fk_NivId"
          options={niveis} // Passa o array de status
          value={fk_NivId} // Usa o estado do status selecionado
          onChange={(e) => handleSelectNivel(e)} // Atualiza o status ao selecionar
          hasError={nivelError} // Aplica a classe de erro se houver erro no status
          valueKey="NivId"
          labelKey="NivDes"
          disabled={user && user.fk_NivId <= 2 ? false : true}
        />

        <Select
          ref={statusRef} // Referência para o campo Status
          label="Status"
          id="UsuStatus"
          options={options} // Passa o array de status
          value={UsuStatus} // Usa o estado do status selecionado
          onChange={(e) => handleSelectStatus(e)} // Atualiza o status ao selecionar
          hasError={statusError} // Aplica a classe de erro se houver erro no status
          disabled={user && user.fk_NivId <= 2 ? false : true}
        />

        <Input
          ref={avatarRef} // Referência para o campo Nome
          label="Avatar"
          id="UsuAvatar"
          type="text"
          value={(UsuAvatar && UsuAvatar) || ""}
          onChange={(e) => {
            setAvatar(e.target.value);
            setAvatarError(false);
            handleCleanError();
          }}
          disabled={true}
          hasError={avatarError} // Aplica a classe de erro se houver erro no nome
        />

        {error && <p className={styles.error}>{error}</p>}
        {formError && <p className={styles.error}>{formError}</p>}

        <Button texto="Salvar" onClick={handleSave} active={true} />
        <Button texto="Cancelar" onClick={handleCancel} active={isActive} />
        <Button
          texto="Alterar Senha"
          onClick={handleAlterarSenha}
          active={isActive}
        />

        <Table
          head={head}
          status={options}
          data={dadosTabela && dadosTabela}
          onClickUpdate={handleClickUpdate}
          onClickDel={handleClickdel}
          isMaster={true}
        />
      </div>
    </div>
  );
};

export default Users;

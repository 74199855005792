import React from "react";

import { Link } from "react-router-dom";

import { useState } from "react";
//import { MenuItems } from "./MenuItems";
//import menu from "../../assets/menu.png";
//import close from "../../assets/close.png";
import { Menus } from "./MenuItems";
import "./Dropdown.css";

const Dropdown = ({ menu, onClick }) => {
  const [click, setClick] = useState(false);

  const handleCLick = (index) => {
    setClick(!click);
    if (index > 0) {
      onClick();
    }
  };

  return (
    <div>
      <ul
        onClick={handleCLick}
        className={click ? "dropdown_menu clicked" : "dropdown_menu"}
      >
        {Menus[menu].map((item, index) => {
          return (
            <li key={index} title={item.title}>
              <Link
                className={item.cName}
                to={item.path}
                onClick={() => handleCLick(index)}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Dropdown;

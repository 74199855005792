import React from "react";
import styles from "./Select.module.css";
import { title } from "process";

const Select = React.forwardRef(
  ({ id, title, options, value, onChange, label, hasError, disabled }, ref) => {
    return (
      <div>
        <label className={styles.select_label} htmlFor={id}>
          {label}
          <select
            title={title}
            alt={title}
            ref={ref}
            className={`${styles.select} ${hasError ? styles.error : ""}`} // Aplica a classe de erro se necessário
            id={id}
            value={value}
            onChange={onChange}
            disabled={disabled}
          >
            {options &&
              options.map((option, index) => (
                <option title={option} alt={option} key={index} value={index}>
                  {option}
                </option>
              ))}
          </select>
        </label>
      </div>
    );
  }
);

export default Select;

import React, { useEffect, useRef, useState } from "react";
import Input from "../../components/Input/Input";
import styles from "../Pages.module.css";
import Button from "../../components/Button/Button";
import { baseURL } from "../../components/utils/utils";
import { useFetch } from "../../hooks/useFetch";
import { useAuthContext } from "../../hooks/useAuthContext";
import Table from "../../components/Table/Table";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";

const Modulos = () => {
  const { httpGet, httpRequest, setError, error, loading } = useFetch(
    `${baseURL}/modulos/`
  );
  const nomRef = useRef();
  const [formError, setFormError] = useState("");
  const [nomeHasError, setNomeHasError] = useState(false);
  const [isActive, setActive] = useState(false);

  const { user } = useAuthContext();
  const [data, setData] = useState([]);

  const [modulo, setModulo] = useState([]);
  const [ModId, setId] = useState("");
  const [ModNome, setNome] = useState("");

  const head = ["Id", "Nome"];
  //Rretorna dados
  const navigate = useNavigate();
  useEffect(() => {
    //console.log("carga");
    const getList = async () => {
      if (user && user.token) {
        const dados = await httpGet(user.token, "listar");
        setData(dados);
      } else {
        navigate("/login");
      }
    };
    getList();
  }, [modulo]);

  // salva os dados do formulário
  const handleSave = async (e) => {
    e.preventDefault();
    const nModulo = validaCampos();
    if (nModulo) {
      var endpoint = "inserir";
      if (ModId > 0) {
        endpoint = `atualizar/${ModId}`;
      }
      if (ModNome !== "") {
        const data = await httpRequest(nModulo, user.token, endpoint);
        if (data) {
          setModulo(data);
          handleCancel();
        }
      }
    }
  };

  // exclui a informação
  const handleClickdel = async (modulo) => {
    setActive(true);
    const success = await httpRequest(
      modulo,
      user.token,
      `remover/${modulo.ModId}`
    );
    if (success) {
      setModulo(data);
      handleCancel();
    }
  };

  //cancela a operação e limpa os campos
  const handleCancel = () => {
    setActive(false); // Desativa o botão "Cancelar"
    setId("");
    setNome("");
    handleCleanError();
  };

  //limpas os erros
  const handleCleanError = () => {
    setFormError("");
    setError("");
  };

  // Direciona as ações
  const handleClickUpdate = (modulo) => {
    setId(modulo.ModId);
    setNome(modulo.ModNome);
    setModulo(modulo);
    setActive(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // valida os campos do formulário
  const validaCampos = () => {
    if (!ModNome || ModNome.trim() === "") {
      setFormError("O campo Módulo é obrigatório.");
      setNomeHasError(true);
      nomRef.current.focus(); // Foca no campo Nome
      return;
    }

    const nModulo = {
      ModId,
      ModNome
    };
    return nModulo;
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Modulos</h1>
      <div className={styles.card}>
        {loading && <Loading />}
        <Input
          ref={nomRef} // Referência para o campo Nome
          label="Módulo"
          type="text"
          value={(ModNome && ModNome) || ""}
          onChange={(e) => {
            setNome(e.target.value);
            setNomeHasError(false);
            handleCleanError();
          }}
          hasError={nomeHasError} // Aplica a classe de erro se houver erro no nome
        />
        {loading && <p className={styles.loading}>Carregando...</p>}
        {error && <p className={styles.error}>{error}</p>}
        {formError && <p className={styles.error}>{formError}</p>}
        <Button texto="Salvar" onClick={handleSave} active={true} />
        <Button texto="Cancelar" onClick={handleCancel} active={isActive} />

        <Table
          head={head}
          data={data && data}
          onClickUpdate={handleClickUpdate}
          onClickDel={handleClickdel}
          isMaster={user && user.fk_NivId > 2 ? false : true}
        />
      </div>
    </div>
  );
};

export default Modulos;

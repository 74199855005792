import React, { forwardRef } from "react";
import styles from "./Checkbox.module.css";

const Checkbox = forwardRef(
  ({ disabled, aria_label, label, checked, onChange }, ref) => {
    return (
      <label
        aria-label={aria_label}
        alt={aria_label}
        title={aria_label}
        className={styles.label}
      >
        <input
          disabled={disabled}
          className={styles.checkbox}
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
        {`    ${label}`}
      </label>
    );
  }
);

export default Checkbox;

import React, { createContext, useState, useContext } from "react";

// Criação do contexto
const DataContext = createContext();

// Hook personalizado para acessar o contexto
export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useData must be used within a DataProvider");
  }
  return context;
};

// Provedor de dados que compartilha o estado global
export const DataProvider = ({ children }) => {
  const [data, setData] = useState(null);

  const updateData = (newData) => {
    setData(newData); // Atualiza o estado com os novos dados
  };

  return (
    <DataContext.Provider value={{ data, updateData }}>
      {children}
    </DataContext.Provider>
  );
};

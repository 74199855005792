import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import { baseURL } from "../../components/utils/utils";
import { useAuthContext } from "../../hooks/useAuthContext";

import tableStyles from "../../components/Table/Table.module.css";
import styles from "../Pages.module.css";
import Button from "../../components/Button/Button";
import Loading from "../../components/Loading/Loading";
const Pagar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { httpGet: httpUsers } = useFetch(`${baseURL}/usuarios/`);
  const { httpGet: httpItens } = useFetch(`${baseURL}/itens/`);
  const { httpGet: httpValor } = useFetch(`${baseURL}/valores/`);
  const {
    httpGet: httpPagos,
    httpRequest: httpPagar,
    loading
  } = useFetch(`${baseURL}/pagamentos/`);

  const { user } = useAuthContext();
  const [usersList, setUsersList] = useState([]);
  const [pagamentoList, setPagando] = useState([]);
  const [itensList, setItensList] = useState([]);
  const [valoresList, setValorList] = useState([]);
  const [pagos, setPagos] = useState([]);
  const [qtd, setQtd] = useState();
  const [ended, setEnded] = useState();

  useEffect(() => {
    const data = location.state?.grouped;
    console.log(data);
    setPagando(data && data);
    localStorage.setItem("pagamentoList", data && data);
    setQtd(pagamentoList.length);
  }, []);

  useEffect(() => {
    const getList = async () => {
      const users = await httpUsers(user.token, `listar/`);
      users && setUsersList(users);
      const itens = await httpItens(user.token, `listar/`);
      itens && setItensList(itens);
      const valores = await httpValor(user.token, `listar/`);
      valores && setValorList(valores);
      const pagos = await httpPagos(user.token, `listar/`);
      pagos && setPagos(pagos);
    };
    getList();
  }, []);

  useEffect(() => {
    // Se não houver mais itens em pagamentoList, redireciona para "/pagamentos"
    if (qtd === 0) {
      navigate("/pagamentos");
    }
  }, [ended]);

  const handlePagar = (pagar) => {
    pagar.forEach((p) => {
      const pagando = {
        fk_ApoId: p.ApoId,
        PagData: getTodayDate(),
        PagValor: retSubTotal(p)
      };

      const execute = async () => {
        const pago = await httpPagar(pagando, user.token, "inserir");
        console.log(pago);
        if (pago) {
          handleRemoveItem(p.fk_UsuId, p.ApoId);
        }
      };
      console.log(qtd);
      if (qtd === 0) {
        setEnded(true);
      }
      execute();
    });
  };

  const handleRemoveItem = (fk_UsuId, id) => {
    setPagando((prevState) => {
      const updatedState = { ...prevState };

      if (updatedState[fk_UsuId]) {
        updatedState[fk_UsuId] = updatedState[fk_UsuId].filter(
          (item) => item.ApoId !== id
        );

        if (updatedState[fk_UsuId].length === 0) {
          delete updatedState[fk_UsuId];
        }
      }

      // Atualiza no localStorage
      localStorage.setItem("pagamentoList", JSON.stringify(updatedState));

      return updatedState;
    });
  };

  const retUserName = (usuId) => {
    const consultor =
      usersList && usersList.find((cons) => cons.UsuId === Number(usuId));
    return consultor ? consultor.UsuNome : null;
  };

  const retItemDescricao = (itemId) => {
    const item =
      itensList &&
      itensList.find((item) => item.ItemId === Number(itemId.fk_ItemId));

    return item ? item.ItemDes : null;
  };
  function decimalToHours(decimal) {
    const hours = Math.floor(decimal); // Parte inteira: horas
    const minutes = Math.round((decimal - hours) * 60); // Parte decimal convertida em minutos
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`; // Formatar como hh:mm
  }

  const retValor = (row) => {
    if (row) {
      const valorHora = valoresList.find(
        (valor) => valor.ValId === Number(row.fk_ValId)
      );
      return valorHora ? Number(valorHora.ValVal1).toFixed(2) : 0;
    }
    return 0;
  };
  const retSubTotal = (row) => {
    if (row) {
      const valorHora = retValor(row);
      const valor = valorHora * row.ApoQtdHoras;
      return valor.toFixed(2);
    }
    return 0;
  };
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Os meses começam em 0
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const listGroupedItems = (grouped) => {
    return Object.entries(grouped).map(([fk_UsuId, items]) => {
      // Calcula o total antes de renderizar
      const total = items.reduce(
        (sum, item) => sum + Number(retSubTotal(item)),
        0
      );

      return (
        <div className={styles.card_pgto} key={fk_UsuId}>
          <h3>{retUserName(fk_UsuId)}</h3>
          <table className={tableStyles.table}>
            <thead>
              <tr>
                <th className={tableStyles.th}>Demanda</th>
                <th className={tableStyles.th}>Data</th>
                <th className={tableStyles.th}>Horas</th>
                <th className={`${tableStyles.right} ${tableStyles.th}`}>
                  ValorHora
                </th>
                <th className={`${tableStyles.right} ${tableStyles.th}`}>
                  Valor
                </th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr key={item.ApoId}>
                  <td>{retItemDescricao(item)}</td>
                  <td className={tableStyles.center}>{item.ApoData}</td>
                  <td className={tableStyles.center}>
                    {decimalToHours(item.ApoQtdHoras)}
                  </td>
                  <td className={tableStyles.right}>{retValor(item)}</td>
                  <td className={tableStyles.right}>{retSubTotal(item)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={styles.total}>
            <label>Valor total R$ {total.toFixed(2)}</label>
          </div>
          <div>
            <Button
              texto="Concluir Pagamento"
              onClick={() => handlePagar(items)}
              active={true}
            />
            <Button
              texto="Cancelar"
              onClick={() => navigate("/pagamentos)")}
              active={true}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}></h1>
      <div className={styles.card}>
        {loading && <Loading />}
        {listGroupedItems(pagamentoList)}
      </div>
    </div>
  );
};

export default Pagar;

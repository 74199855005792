import React, { useEffect, useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import { baseURL } from "../../components/utils/utils";
import { useAuthContext } from "../../hooks/useAuthContext";
import styles from "../Pages.module.css";
import tableStyles from "../../components/Table/Table.module.css";
import { useNavigate } from "react-router-dom";
import Checkbox from "../../components/Checkbox/Checkbox";
import SelectObjetos from "../../components/Select/SelectObjetos";
import {
  delIcon,
  editIcon,
  pagarIco,
  pagoIco,
  receberIco,
  inactivePagar
} from "../../components/utils/iconsPath";

const Registros = () => {
  const navigate = useNavigate();
  const { httpGet, httpRequest, error, setError, loading } = useFetch(
    `${baseURL}/apontamentos/`
  );
  const { httpGet: httpUsers } = useFetch(`${baseURL}/usuarios/`);
  const { httpGet: httpItens } = useFetch(`${baseURL}/itens/`);
  const { httpGet: httpValor } = useFetch(`${baseURL}/valores/`);
  const { httpGet: httpPagos } = useFetch(`${baseURL}/pagamentos/`);

  const { user } = useAuthContext();
  const [deleted, setDeleted] = useState(false);

  const [valores, setValorList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [itensList, setItensList] = useState([]);
  const [pagosList, setPagos] = useState([]);

  const [fk_UsuId, setUsuId] = useState(0);

  const [filteredItems, setFilteredItems] = useState([]);
  const [showPending, setShowPending] = useState(false);
  const [showPayed, setShowPayed] = useState(false);
  const [showConfirmed, setShowConfirmed] = useState(false);

  const [dadosTabela, setTabela] = useState([]);

  useEffect(() => {
    const getList = async () => {
      if (user && user.token) {
        if (user && user.fk_NivId > 2) {
          setUsuId(user.UsuId);
        }

        const users = await httpUsers(user.token, `listar/`);
        users && setUsersList(users);
        const itens = await httpItens(user.token, `listar/`);
        itens && setItensList(itens);
        const valores = await httpValor(user.token, `listar/`);
        valores && setValorList(valores);
        const pagos = await httpPagos(user.token, `listar/`);
        pagos && setPagos(pagos);
        console.log(pagos);
        const dados = await httpGet(user.token, "listar/");
        if (dados && dados) {
          setTabela(dados);
          setFilteredItems(dados);
        }
      } else {
        navigate("/login");
      }
      setDeleted(false);
    };
    getList();
  }, [deleted, fk_UsuId]);

  useEffect(() => {
    const filters = [];
    if (showPending) filters.push(1); // Status Pendente
    if (showPayed) filters.push(2); // Status Pago
    if (showConfirmed) filters.push(3); // Status Confirmado

    let result = [...dadosTabela];

    // Filtro por UsuId
    if (fk_UsuId !== 0) {
      result = result.filter((item) => item.fk_UsuId === fk_UsuId);
    }

    // Filtro por status
    if (filters.length > 0) {
      result = result.filter((item) => filters.includes(item.ApoStatus));
    }

    setFilteredItems(result);
  }, [showPending, showPayed, showConfirmed, fk_UsuId, dadosTabela]);

  const handleClickdel = async (apontamento) => {
    if (apontamento) {
      const success = await httpRequest(
        apontamento,
        user.token,
        `remover/${apontamento.ApoId}`
      );
      if (success) {
        setDeleted(true);
      }
    }
  };

  const retStatus = (apontamento) => {
    const pago =
      pagosList &&
      pagosList.find((pago) => pago.fk_ApoId === Number(apontamento.ApoId));
    if (apontamento.ApoStatus === 1 && !pago) {
      return "Pendente";
    } else if (apontamento.ApoStatus === 2 && pago) {
      return "Pago";
    } else if (apontamento.ApoStatus === 3 && pago) {
      return "Confirmado";
    } else {
      return "Inconsistente";
    }
  };

  const retUserName = (usuId) => {
    const consultor = usersList.find((cons) => cons.UsuId === Number(usuId));
    return consultor.UsuNome;
  };

  const retItemDescricao = (itemId) => {
    const item = itensList.find((item) => item.ItemId === Number(itemId));
    return item && item.ItemDes;
  };

  function decimalToHours(decimal) {
    const hours = Math.floor(decimal); // Parte inteira: horas
    const minutes = Math.round((decimal - hours) * 60); // Parte decimal convertida em minutos
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`; // Formatar como hh:mm
  }
  const onClickUpdate = (apontamento) => {
    navigate("/registrar", { state: { apontamento } });
  };

  const handleSelectUsers = async (e) => {
    const selectedId = Number(e.target.value);
    setUsuId(selectedId);

    if (selectedId) {
      const filtered = dadosTabela.filter(
        (item) => item.fk_UsuId === selectedId
      );
      setFilteredItems(filtered);
    } else {
      setFilteredItems(dadosTabela); // Mostra todos se nenhum consultor for selecionado
    }
  };
  const retSubTotal = (row) => {
    if (row) {
      const valorHora = retValor(row);
      const valor = valorHora * row.ApoQtdHoras;
      return valor.toFixed(2);
    }
    return 0;
  };
  const retValor = (row) => {
    if (row) {
      const valorHora = valores.find(
        (valor) => valor.ValId === Number(row.fk_ValId)
      );

      return Number(valorHora && valorHora.ValVal1).toFixed(2);
    }
    return 0;
  };

  const retIcone = (row) => {
    switch (row.ApoStatus) {
      case 1:
        return pagarIco;
      case 2:
        return receberIco;
      case 3:
        return pagoIco;
      default:
        return inactivePagar;
    }
  };
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Apontamentos realizados</h1>
      <div className={styles.card}>
        <div>
          <SelectObjetos
            label="Usuário"
            id="fk_UsuId"
            options={usersList}
            value={fk_UsuId ? fk_UsuId : 0}
            onChange={(e) => handleSelectUsers(e)}
            valueKey="UsuId"
            labelKey="UsuNome"
            disabled={false}
          />
          <div className={styles.checkbox_container}>
            <Checkbox
              checked={showPending}
              onChange={(e) => setShowPending(e.target.checked)}
              label="A pagar"
            />
            <Checkbox
              checked={showPayed}
              onChange={(e) => setShowPayed(e.target.checked)}
              label="A confirmar"
            />
            <Checkbox
              checked={showConfirmed}
              onChange={(e) => setShowConfirmed(e.target.checked)}
              label="Pago"
            />
          </div>
        </div>

        <table className={tableStyles.table}>
          <thead>
            <tr className={tableStyles.tr}>
              <th className={tableStyles.th}>Status</th>
              <th className={tableStyles.th}>Consultor</th>
              <th className={tableStyles.th}>Tarefa</th>
              <th className={tableStyles.th}>Data</th>
              <th className={tableStyles.th}>Apontado</th>
              <th className={tableStyles.th}>R$ / Hora</th>
              <th className={tableStyles.th}>Valor</th>
              <th className={tableStyles.th}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems &&
              filteredItems.map((row) => {
                return (
                  <tr className={tableStyles.tr} key={row.ApoId}>
                    <td className={tableStyles.tdIcon}>
                      <img
                        className={tableStyles.inactiveIcon}
                        src={retIcone(row)}
                        alt={retStatus(row)}
                        title={retStatus(row)}
                      />
                    </td>
                    <td className={tableStyles.td}>
                      {retUserName(row.fk_UsuId)}
                    </td>
                    <td className={tableStyles.td}>
                      {retItemDescricao(row.fk_ItemId)}
                    </td>
                    <td className={`${tableStyles.td} ${tableStyles.center}`}>
                      {row.ApoData}
                    </td>
                    <td className={`${tableStyles.td} ${tableStyles.center}`}>
                      {decimalToHours(row.ApoQtdHoras)}
                    </td>
                    <td className={`${tableStyles.td} ${tableStyles.right}`}>
                      {retValor(row)}
                    </td>
                    <td className={`${tableStyles.td} ${tableStyles.right}`}>
                      {retSubTotal(row)}
                    </td>

                    <td className={tableStyles.tdIcon}>
                      <img
                        className={tableStyles.icon}
                        src={editIcon}
                        alt="Editar"
                        title="Editar"
                        onClick={() => onClickUpdate(row)}
                      />
                      <img
                        className={tableStyles.icon}
                        src={delIcon}
                        alt="Excluir"
                        title="Excluir"
                        onClick={() => handleClickdel(row)}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Registros;

import React, { useEffect, useRef } from "react";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";

import { useAuthContext } from "../../hooks/useAuthContext";

import styles from "./Login.module.css";

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import { baseURL } from "../../components/utils/utils";
import Loading from "../../components/Loading/Loading";

const Login = () => {
  const { httpRequest, error, setError, loading } = useFetch(
    `${baseURL}/usuarios/`
  );
  const { loading: loginLoadin, setUser, user } = useAuthContext();
  const senhaRef = useRef();
  const emailRef = useRef();
  const [UsuEmail, setEmail] = useState("");
  const [UsuSenha, setSenha] = useState("");
  const [formError, setFormError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [senhaError, setSenhaError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.token && user !== null) {
      navigate("/");
    }
  }, [user, navigate]);

  const handleLogin = async () => {
    const nUser = validaCampos();
    if (nUser) {
      const data = await httpRequest(nUser, null, "login");
      if (data) {
        localStorage.setItem("user", JSON.stringify(data));
        setUser(data);
      }
    }
  };

  const validaCampos = () => {
    const d = {
      UsuEmail,
      UsuSenha
    };
    if (d.UsuEmail === "") {
      setFormError("Informar um e-mail!");
      setEmailError(true);
      emailRef.current.focus(); // Foca no campo Nome
      return;
    }
    if (d.UsuSenha === "") {
      setFormError("Informar a senha!");
      setSenhaError(true);
      senhaRef.current.focus(); // Foca no campo Nome
      return;
    }
    return d;
  };

  return (
    <div className={styles.container_login}>
      <h1 className={styles.title}>Login</h1>
      <div className={styles.content}>
        {loginLoadin && <Loading />}
        <Input
          ref={emailRef}
          label="E-mail"
          id="UsuEmail"
          type="email"
          placeholder="Digite seu E-mail"
          value={UsuEmail}
          onChange={(e) => [
            setEmail(e.target.value),
            setFormError(""),
            setError("")
          ]}
          hasError={emailError}
        />
        <Input
          ref={senhaRef}
          label="Senha"
          id="UsuSenha"
          type="password"
          placeholder="Digite sua Senha"
          value={UsuSenha}
          onChange={(e) => [
            setSenha(e.target.value),
            setFormError(""),
            setError("")
          ]}
          hasError={senhaError}
        />

        {loading && <p className={styles.loading}>Carregando...</p>}
        {error && <p className={styles.error}>{error}</p>}
        {formError && <p className={styles.error}>{formError}</p>}
        <Button texto="Entrar" onClick={handleLogin} active={true} />
        <p className={styles.p}>
          Não tem conta?
          <strong>
            <Link to="/users">&nbsp;Registre-se</Link>
          </strong>
        </p>
      </div>
    </div>
  );
};
export default Login;

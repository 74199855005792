const editIcon = "https://tagsistemasdigitais.com.br/frontend/assets/edit.svg";
const delIcon = "https://tagsistemasdigitais.com.br/frontend/assets/delIco.svg";
const inactiveDelIcon =
  "https://tagsistemasdigitais.com.br/frontend/assets/inactiveDel.svg";
const inactiveEditIcon =
  "https://tagsistemasdigitais.com.br/frontend/assets/inactiveEdit.svg";
const pagarIco =
  "https://tagsistemasdigitais.com.br/frontend/assets/pagarVermelho.svg";
const receberIco =
  "https://tagsistemasdigitais.com.br/frontend/assets/receberAmarelo.svg";
const pagoIco =
  "https://tagsistemasdigitais.com.br/frontend/assets/pagoVerde.svg";
const inactivePagar =
  "https://tagsistemasdigitais.com.br/frontend/assets/inactivePagar.svg";
const loadIco =
  "https://srv805-files.hstgr.io/ae1e247c6e845f93/files/public_html/assets/loading.svg";
const avatarIco =
  "https://tagsistemasdigitais.com.br/_API_2_/avatar/avatar.png";

export {
  editIcon,
  delIcon,
  inactiveDelIcon,
  inactiveEditIcon,
  pagarIco,
  pagoIco,
  receberIco,
  inactivePagar,
  loadIco,
  avatarIco
};

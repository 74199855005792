import React, { forwardRef } from "react";
import styles from "./Select.module.css";

const SelectObjetos = forwardRef(
  (
    {
      id,
      label,
      options,
      value,
      onChange,
      hasError,
      disabled,
      valueKey,
      labelKey,
      statusField,
      title
    },
    ref
  ) => {
    return (
      <div>
        <label className={styles.select_label} htmlFor={id}>
          {label}
        </label>
        <select
          alt={title}
          title={title}
          ref={ref}
          className={`${styles.select} ${hasError ? styles.error : ""}`}
          value={value}
          onChange={onChange}
          disabled={disabled}
        >
          <option
            value=""
            alt="Nenhum item selecionado"
            title="Nenhum item selecionado"
          >
            ...
          </option>
          {options &&
            options.map((option) =>
              option[statusField] != 1 ? (
                <option
                  alt={option[labelKey]}
                  title={option[labelKey]}
                  key={option[valueKey]}
                  value={option[valueKey]}
                >
                  {option[labelKey]}
                </option>
              ) : null
            )}
        </select>
      </div>
    );
  }
);

export default SelectObjetos;

import React, { useEffect, useRef, useState } from "react";
import Input from "../../components/Input/Input";
import styles from "../Pages.module.css";
import Select from "../../components/Select/Select";
import Button from "../../components/Button/Button";
import { baseURL } from "../../components/utils/utils";
import { useFetch } from "../../hooks/useFetch";
import { useAuthContext } from "../../hooks/useAuthContext";
import Table from "../../components/Table/Table";
import SelectObjetos from "../../components/Select/SelectObjetos";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";

const Projetos = () => {
  const navigate = useNavigate();
  const { setUser, loading: autenticando, setUrl } = useAuthContext();
  // instancia o useFetch de comunicação com API
  const { httpGet, httpRequest, error, setError, loading } = useFetch(
    `${baseURL}/projetos/`
  );
  const { httpGet: httpCliente } = useFetch(`${baseURL}/clientes/`);
  const { httpGet: httpGerente } = useFetch(`${baseURL}/gerentes/`);

  const nomRef = useRef();
  const cliRef = useRef();
  const gerRef = useRef();
  const horRef = useRef();
  const statusRef = useRef();
  const [formError, setFormError] = useState("");

  const [isCancelActive, setCancelActive] = useState(false);
  const [isSavelActive, setSavelActive] = useState(false);
  //trata erros de formulário
  const [horasError, setHorasError] = useState("");
  const [nomeError, setNomeError] = useState("");
  const [statusError, setStatusError] = useState("");
  const [clienteError, setClienteError] = useState("");
  const [gerenteError, setGerenteError] = useState("");

  const { user } = useAuthContext();
  const [data, setData] = useState([]);
  const [dadosTabela, setTabela] = useState([]);

  const head = ["Id", "Gerente", "Cliente", "Nome", "Horas", "Status"];
  const statusOptions = ["...", "Pendente", "Em andamento", "Concluído"];

  //chaves estrangeiras
  const [clienteList, setCliList] = useState([]);
  const [gerenteList, setGerList] = useState([]);

  // dados do formulário
  const [projeto, setProjeto] = useState([]);
  const [ProjId, setId] = useState("");
  const [ProjNome, setNome] = useState("");
  const [ProjStatus, setStatus] = useState(0);
  const [ProjQtdHora, setHoras] = useState(0);
  const [fk_CliId, setCliId] = useState(0);
  const [fk_GerId, setGerId] = useState(0);
  // Effects de retorno dos dados:
  useEffect(() => {
    const fetchSelects = async () => {
      if (user && user?.token) {
        const clientesData = await httpCliente(user.token, "listar");
        setCliList(clientesData); // Armazena os clientes
        const gerentesData = await httpGerente(user.token, "listar");
        setGerList(gerentesData); // Armazena os gerentes
        const dados = await httpGet(user.token, "listar");
        const dadosTabela = objToList(dados);
        dadosTabela && setTabela(dadosTabela);
        //console.log(dados);
        setData(dados);
      } else {
        navigate("/login");
      }
    };
    fetchSelects();
  }, [user, projeto]);

  // functions
  const handleSave = async (e) => {
    e.preventDefault();
    setCancelActive(true);
    const nprojeto = validaCampos();
    if (nprojeto) {
      var endpoint = "inserir";
      if (ProjId > 0) {
        endpoint = `atualizar/${ProjId}`;
      }
      if (ProjNome !== "") {
        const data = await httpRequest(nprojeto, user.token, endpoint);
        if (data) {
          setProjeto(data);
          handleCancel();
        }
      }
    }
  };

  const handleClickdel = async (projDeleting) => {
    const success = await httpRequest(
      projDeleting,
      user.token,
      `remover/${projDeleting.ProjId}`
    );
    if (success) {
      setProjeto(data);
      handleCancel();
    }
  };

  const handleCancel = () => {
    setCancelActive(false); // Desativa o botão "Cancelar"
    setId("");
    setNome("");
    setHoras(0);
    setError("");
    setStatus(0);
    setCliId(0);
    setGerId(0);
    handleCleanError();
  };
  const handleCleanError = () => {
    setHorasError(false);
    setFormError(false);
    setError(false);
    setClienteError(false);
    setGerenteError(false);
    setNomeError(false);
    setHorasError(false);
    setStatusError(false);
  };

  const handleClickUpdate = (projEditing) => {
    const eProje = data.find((user) => user.ProjId === projEditing.ProjId);
    setProjeto(eProje);
    setCancelActive(true);
    setId(eProje.ProjId);
    setNome(eProje.ProjNome);
    setStatus(eProje.ProjStatus); // Atualiza o status selecionado
    setCliId(eProje.fk_CliId);
    setGerId(eProje.fk_GerId);
    setCliId(eProje.fk_CliId);
    setHoras(eProje.ProjQtdHora);
  };

  // açoes dos selects
  const handleSelectGerente = (e) => {
    console.log(e.target.value);
    setFormError("");
    setError("");
    setGerId(e.target.value);
  };
  const handleSelectCliente = (e) => {
    console.log(e);
    setFormError("");
    setError("");
    setCliId(e.target.value);
  };

  const handleSelectStatus = (e) => {
    console.log(e.target.value);
    setFormError("");
    setError("");
    setStatus(e.target.value);
  };

  const validaCampos = () => {
    // Valida o campo Nome
    console.log(ProjNome);
    if (!ProjNome || ProjNome.trim() === "") {
      setFormError("O campo Nome do Projeto é obrigatório.");
      setNomeError(true);
      nomRef.current.focus(); // Foca no campo Nome
      return;
    }
    if (!ProjQtdHora || !isInteger(ProjQtdHora)) {
      setFormError("O campo Horas do Projeto deve ser um número");
      setHorasError(true);
      horRef.current.focus(); // Foca no campo Nome
      return;
    }
    // Valida o campo Cliente
    if (fk_CliId === 0) {
      setFormError("Selecione um Cliente.");
      setClienteError(true);
      cliRef.current.focus(); // Foca no campo Status
      return;
    }
    // Valida o campo Cliente
    if (fk_GerId === 0) {
      setFormError("Selecione um Gerente.");
      setGerenteError(true);
      gerRef.current.focus(); // Foca no campo Status
      return;
    }

    // Valida o campo Status
    if (ProjStatus === 0) {
      setFormError("O campo Status é obrigatório.");
      setStatusError(true);
      statusRef.current.focus(); // Foca no campo Status
      return;
    }

    const nprojeto = {
      ProjId,
      ProjNome,
      ProjStatus,
      ProjQtdHora,
      fk_GerId,
      fk_CliId
    };

    return nprojeto;
  };
  const isInteger = (value) => {
    const regex = /^[0-9]+$/;
    return regex.test(value); // Retorna true se for um número inteiro
  };

  const objToList = (dados) => {
    return (
      dados &&
      dados.map((obj) =>
        Object.keys(obj).reduce((acc, key) => {
          if (
            key === "ProjId" ||
            key === "ProjNome" ||
            key === "ProjStatus" ||
            key === "ProjQtdHora" ||
            key === "fk_GerId" ||
            key === "fk_CliId"
          ) {
            acc[key] = obj[key];
          }
          return acc;
        }, {})
      )
    );
  };
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Projetos</h1>
      <div className={styles.card}>
        {loading && <Loading />}
        <Input
          ref={nomRef} // Referência para o campo Nome
          label="Nome do Projeto"
          id="ProjNome"
          type="text"
          value={(ProjNome && ProjNome) || ""}
          onChange={(e) => {
            setNome(e.target.value);
            handleCleanError();
            setCancelActive(true);
          }}
          hasError={nomeError} // Aplica a classe de erro se houver erro no nome
        />

        <Input
          ref={horRef} // Referência para o campo Nome
          label="Horas previstas"
          id="ProjQtdHora"
          type="text"
          value={(ProjQtdHora && ProjQtdHora) || ""}
          onChange={(e) => {
            setHoras(e.target.value);
            handleCleanError();
            setCancelActive(true);
          }}
          hasError={horasError} // Aplica a classe de erro se houver erro no nome
        />

        <SelectObjetos
          ref={cliRef} // Referência para o campo Status
          label="Cliente"
          id="fk_CliId"
          options={clienteList}
          value={fk_CliId}
          onChange={(e) => handleSelectCliente(e)}
          hasError={clienteError}
          valueKey="CliId"
          labelKey="CliNome"
          statusField="CliStatus"
        />

        <SelectObjetos
          ref={gerRef} // Referência para o campo Status
          label="Gerente"
          id="fk_GerId"
          options={gerenteList}
          value={fk_GerId}
          onChange={(e) => handleSelectGerente(e)}
          hasError={gerenteError}
          valueKey="GerId"
          labelKey="GerNome"
          statusField="GerStatus"
        />

        <Select
          ref={statusRef} // Referência para o campo Status
          label="Status"
          id="ProjStatus"
          options={statusOptions} // Passa o array de status
          value={ProjStatus} // Usa o estado do status selecionado
          onChange={(e) => handleSelectStatus(e)} // Atualiza o status ao selecionar
          hasError={statusError} // Aplica a classe de erro se houver erro no status
        />
        {loading && <p className={styles.loading}>Carregando...</p>}
        {error && <p className={styles.error}>{error}</p>}
        {formError && <p className={styles.error}>{formError}</p>}
        <Button texto="Salvar" onClick={handleSave} active={true} />
        <Button
          texto="Cancelar"
          onClick={handleCancel}
          active={isCancelActive}
        />
        <Table
          head={head}
          status={statusOptions}
          data={dadosTabela && dadosTabela}
          onClickUpdate={handleClickUpdate}
          onClickDel={handleClickdel}
          listas={
            clienteList && { fk_CliId: clienteList, fk_GerId: gerenteList }
          }
          isMaster={user && user.fk_NivId > 2 ? false : true}
        />
      </div>
    </div>
  );
};

export default Projetos;

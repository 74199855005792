import styles from "./Button.module.css";
import React from "react";
const Button = ({ texto, onClick, active }) => {
  return (
    <button
      alt={texto}
      title={texto}
      className={`${styles.botao} ${active ? styles.active : ""}`}
      onClick={active ? onClick : null}
    >
      {texto}
    </button>
  );
};

export default Button;

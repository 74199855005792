import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import { baseURL } from "../../components/utils/utils";
import { useAuthContext } from "../../hooks/useAuthContext";

import tableStyles from "../../components/Table/Table.module.css";
import styles from "../Pages.module.css";
import SelectObjetos from "../../components/Select/SelectObjetos";
import {
  inactiveDelIcon,
  delIcon,
  pagarIco,
  pagoIco,
  receberIco,
  inactivePagar
} from "../../components/utils/iconsPath";

import Loading from "../../components/Loading/Loading";
const Pagos = () => {
  const navigate = useNavigate();

  const { httpGet: httpUsers } = useFetch(`${baseURL}/usuarios/`);
  const { httpGet: httpItens } = useFetch(`${baseURL}/itens/`);
  const { httpGet: httpValor } = useFetch(`${baseURL}/valores/`);
  const {
    httpGet: httpPagos,
    httpRequest,
    loading
  } = useFetch(`${baseURL}/pagamentos/`);

  const { user } = useAuthContext();
  const [usersList, setUsersList] = useState([]);
  const [fk_UsuId, setUsuId] = useState(0);
  const [itensList, setItensList] = useState([]);
  const [valoresList, setValorList] = useState([]);
  const [dadosTabela, setTabela] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const [pagosList, setPagos] = useState([]);

  const [filteredItems, setFilteredItems] = useState([]);
  useEffect(() => {
    const getList = async () => {
      if (user && user.token) {
        const users = await httpUsers(user.token, `listar/`);
        users && setUsersList(users);
        const itens = await httpItens(user.token, "listar/");
        itens && setItensList(itens);
        const valores = await httpValor(user.token, `listar/`);
        valores && setValorList(valores);
        const pagamentos = await httpPagos(user.token, `listarPorApontamento/`);

        const pagos = await httpPagos(user.token, `listar/`);
        pagos && setPagos(pagos);

        pagamentos && setTabela(pagamentos);
        setFilteredItems(pagamentos);
        setDeleted(false);
        console.log(user.fk_NivId);
      } else {
        navigate("/login");
      }
    };
    getList();
  }, [deleted]);
  useEffect(() => {
    const filters = [];

    let result = [...dadosTabela];

    // Filtro por UsuId
    if (fk_UsuId !== 0) {
      result = result.filter((item) => item.fk_UsuId === fk_UsuId);
    }

    // Filtro por status
    if (filters.length > 0) {
      result = result.filter((item) => filters.includes(item.ApoStatus));
    }

    setFilteredItems(result);
  }, [fk_UsuId, dadosTabela, deleted]);

  const handleSelectUsers = async (e) => {
    const selectedId = Number(e.target.value);
    setUsuId(selectedId);

    if (selectedId) {
      const filtered = dadosTabela.filter(
        (item) => item.fk_UsuId === selectedId
      );
      setFilteredItems(filtered);
    } else {
      setFilteredItems(dadosTabela); // Mostra todos se nenhum consultor for selecionado
    }
  };
  const retUserName = (usuId) => {
    const consultor =
      usersList && usersList.find((cons) => cons.UsuId === Number(usuId));
    return consultor ? consultor.UsuNome : null;
  };

  const retItemDescricao = (itemId) => {
    const item =
      itensList && itensList.find((item) => item.ItemId === Number(itemId));
    return item ? item.ItemDes : null;
  };
  function decimalToHours(decimal) {
    const hours = Math.floor(decimal); // Parte inteira: horas
    const minutes = Math.round((decimal - hours) * 60); // Parte decimal convertida em minutos
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`; // Formatar como hh:mm
  }

  const handleClickdel = (pagamento) => {
    if (user.fk_NivId === 2) {
      if (pagamento.ApoStatus !== 3) {
        remover(pagamento);
      }
    } else if (user.fk_NivId === 1) {
      remover(pagamento);
    }
    /*  if ((pagamento && pagamento.ApoStatus !== 3) || user.fk_NivId === 1) {
     */
  };
  const remover = async (pagamento) => {
    const success = await httpRequest(
      pagamento,
      user.token,
      `remover/${pagamento.ApoId}`
    );
    console.log(success);
    setDeleted(true);
  };

  const retValor = (row) => {
    if (row) {
      const valorHora = valoresList.find(
        (valor) => valor.ValId === Number(row.fk_ValId)
      );
      return valorHora ? Number(valorHora.ValVal1).toFixed(2) : 0;
    }
    return 0;
  };
  const retSubTotal = (row) => {
    if (row) {
      const valorHora = retValor(row);
      const valor = valorHora * row.ApoQtdHoras;
      return valor.toFixed(2);
    }
    return 0;
  };

  const retIcone = (row) => {
    switch (row.ApoStatus) {
      case 1:
        return pagarIco;
      case 2:
        return receberIco;
      case 3:
        return pagoIco;
      default:
        return inactivePagar;
    }
  };
  const retStatus = (apontamento) => {
    const pago =
      pagosList &&
      pagosList.find((pago) => pago.fk_ApoId === Number(apontamento.ApoId));

    if (apontamento.ApoStatus === 1 && !pago) {
      return "Pendente";
    } else if (apontamento.ApoStatus === 2 && pago) {
      return "Confirmar";
    } else if (apontamento.ApoStatus === 3 && pago) {
      return "Pago";
    } else {
      return "Inconsistente";
    }
  };
  return (
    <div className={styles.container}>
      <h1 className={styles.title}></h1>
      <div className={styles.card}>
        <div>
          {loading && <Loading />}
          <SelectObjetos
            label="Usuário"
            id="fk_UsuId"
            options={usersList}
            value={fk_UsuId ? fk_UsuId : 0}
            onChange={(e) => handleSelectUsers(e)}
            valueKey="UsuId"
            labelKey="UsuNome"
            disabled={false}
          />
        </div>

        <table className={tableStyles.table}>
          <thead>
            <tr className={tableStyles.tr}>
              <th className={tableStyles.th}>Status</th>
              <th className={tableStyles.th}>Consultor</th>
              <th className={tableStyles.th}>Tarefa</th>
              <th className={tableStyles.th}>Data</th>
              <th className={tableStyles.th}>Apontado</th>
              <th className={tableStyles.th}>Valor</th>
              <th className={tableStyles.th}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems &&
              filteredItems.map((row) => {
                return (
                  <tr className={tableStyles.tr} key={row.ApoId}>
                    <td className={tableStyles.tdIcon}>
                      <img
                        className={tableStyles.inactiveIcon}
                        src={retIcone(row)}
                        alt={retStatus(row)}
                        title={retStatus(row)}
                      />
                    </td>
                    <td className={tableStyles.td}>
                      {retUserName(row.fk_UsuId)}
                    </td>
                    <td className={tableStyles.td}>
                      {retItemDescricao(row.fk_ItemId)}
                    </td>
                    <td className={`${tableStyles.td} ${tableStyles.center}`}>
                      {row.ApoData}
                    </td>
                    <td className={`${tableStyles.td} ${tableStyles.center}`}>
                      {decimalToHours(row.ApoQtdHoras)}
                    </td>

                    <td className={`${tableStyles.td} ${tableStyles.right}`}>
                      {retSubTotal(row)}
                    </td>

                    <td className={tableStyles.tdIcon}>
                      <img
                        className={tableStyles.icon}
                        src={
                          user.fk_NivId < 3
                            ? inactiveDelIcon
                            : row.ApoStatus < 3
                            ? delIcon
                            : inactiveDelIcon
                        }
                        alt="Excluir"
                        title="Excluir"
                        onClick={() =>
                          user.fk_NivId < 3 ? handleClickdel(row) : null
                        }
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Pagos;
